import { apiBaseUrl } from "@/config"
import axios from "axios"

const service = axios.create({
  timeout: 30000,
  //baseURL: '/',
  headers: {
    "Content-Type": "application/json",
    "Accept-Encoding": "gzip"
  }
})

const BASE_URL = `/`

service.defaults.baseURL = BASE_URL

// 取消重复请求
const pending = []

const baseURLForCancel = apiBaseUrl
// 取消请求白名单
const WhiteCancelList = [baseURLForCancel + "games/(.*)&request_type=get"]

// 每次请求，产生取消方法，可对外取消
let cancels = null

const CancelToken = axios.CancelToken

function formatURL(url, config) {
  const baseURL = config.baseURL ? config.baseURL : BASE_URL

  let compareURL = url
  if (/^\//.test(compareURL)) {
    compareURL = baseURL + compareURL.substring(1)
  } else if (!/^https?:\/\//.test(compareURL)) {
    compareURL = baseURL + compareURL
  }

  return compareURL
}

const removePending = config => {
  for (const p of pending.entries()) {
    const index = p[0]
    const item = p[1]
    let compareURL = item.url

    compareURL = formatURL(compareURL, config)

    let configURL = config.url

    configURL = formatURL(configURL, config)

    if (compareURL === configURL + "&request_type=" + config.method) {
      // 执行取消操作
      const isWhite = WhiteCancelList.some(_ => {
        const r = new RegExp(_)
        return r.test(compareURL)
      })

      if (!isWhite) {
        item.cancel(`request cancel ${compareURL}`)
      }
      // 从数组中移除记录
      pending.splice(index, 1)
    }
  }
}

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    // // 每次请求删除之前的请求
    // removePending(config)

    // config.cancelToken = new CancelToken(cancelFn => {
    //   // 发送请求时添加到数组， 同时将回调的赋值到cancel【注意这里是引用】
    //   cancels = cancelFn
    //   pending.push({
    //     url: config.url + "&request_type=" + config.method,
    //     cancel: cancelFn
    //   })
    // })
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 返回状态判断(添加响应拦截器)
service.interceptors.response.use(
  res => {
    // removePending(res.config)
    return res
  },
  error => {
    // if (error && error.message && error.message.indexOf("timeout") > -1) {
    //   // 超时处理
    // }
    // if (!error) return

    return Promise.reject(error)
  }
)

export default {
  service,
  cancels,
  axios
}
