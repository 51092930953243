










import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "Error",
  components: {
    Empty: () => import("@/components/empty/empty.vue"),
    Footer: () => import("@/components/footer/footer.vue"),
    Header: () => import("@/views/BbsHome/components/Header.vue")
  }
})
export default class Error extends Vue {}
