import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=9c196ba4&scoped=true"
import script from "./Article.vue?vue&type=script&lang=ts"
export * from "./Article.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Article.vue?vue&type=style&index=0&id=9c196ba4&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9c196ba4",
  "6916482e"
  
)

export default component.exports