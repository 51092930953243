




























































import { NVideoComment } from "@/api/modules/video"
import { ITableData } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import api from "@/api/api"
@Component({
  name: "Comments",
  async beforeRouteUpdate(to, from, next) {
    this.sort = "time.create:-1"
    next()
  },
  components: {
    CommentItem: () => import("./CommentItem.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue")
  }
})
export default class Comments extends Vue {
  @Prop({ type: Object, default: () => {} }) data: ITableData<
    NVideoComment.IItem
  >

  sort = "time.create:-1"

  reset() {
    this.sort = "time.create:-1"
  }

  loading = false

  handleLoadMore() {
    this.getComments()
  }

  async getComments() {
    this.loading = true
    const res = await api.video.comments(this.$route.params.video_id, {
      page: this.data.page,
      page_size: this.data.page_size,
      sort: this.sort
    })
    if (res.data.length < this.data.page_size) {
      this.$root.$tstore.modules.content.mutations.SET_VIDEO_COMMENTS({
        data: this.data.data.concat(res.data),
        finished: true
      })
    } else {
      this.$root.$tstore.modules.content.mutations.SET_VIDEO_COMMENTS({
        data: this.data.data.concat(res.data),
        page: this.data.page + 1
      })
    }

    this.loading = false
  }

  @Watch("sort")
  onSort(_s) {
    this.$root.$tstore.modules.content.mutations.RESET_VIDEO_COMMENTS()
    this.$nextTick(() => {
      this.getComments()
    })
  }

  async handleLoadMoreReplys(item: NVideoComment.IItem, idx: number) {
    const res = await api.video.commentsReplys(
      this.$route.params.video_id,
      item._seq
    )
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_COMMENTS_REPLYS({
      idx,
      replys: res.data
    })
  }

  refresh() {
    this.$root.$tstore.modules.content.mutations.RESET_VIDEO_COMMENTS()
    this.$nextTick(() => {
      this.getComments()
    })
  }
}
