import { render, staticRenderFns } from "./Hot.vue?vue&type=template&id=3ca4f391&scoped=true"
import script from "./Hot.vue?vue&type=script&lang=ts"
export * from "./Hot.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Hot.vue?vue&type=style&index=0&id=3ca4f391&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3ca4f391",
  "20d268af"
  
)

export default component.exports