export type ScrollElement = HTMLElement | Window

const overflowScrollReg = /scroll|auto/i

const cubic = value => Math.pow(value, 3)
const easeInOutCubic = value =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2

export function isWindow(el) {
  return el && el.window === window
}
// 具体dom，如果是window，则应该document.body
export function scrollToTop(element) {
  const el = element
  let isRoot = false
  if (isWindow(el)) {
    isRoot = true
  }
  const duration = 500
  const beginTime = Date.now()
  const beginValue = isRoot
    ? document.body.scrollTop ||
      (document.documentElement && document.documentElement.scrollTop) ||
      0
    : el.scrollTop
  const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
  const frameFunc = () => {
    const progress = (Date.now() - beginTime) / duration
    if (progress < 1) {
      if (isRoot) {
        setScrollTop(beginValue * (1 - easeInOutCubic(progress)))
      } else {
        el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
      }
      rAF(frameFunc)
    } else {
      if (isRoot) {
        setScrollTop(0)
      } else {
        el.scrollTop = 0
      }
    }
  }
  rAF(frameFunc)
}

function setScrollTop(value) {
  document.body.scrollTop = value
  if (document.documentElement) {
    document.documentElement.scrollTop = value
  }
}

export function getScrollEventTarget(
  element: HTMLElement,
  rootParent: ScrollElement = window
): ScrollElement {
  let node = element
  while (
    node &&
    node.tagName !== "HTML" &&
    node.nodeType === 1 &&
    node !== rootParent
  ) {
    const { overflowY } = window.getComputedStyle(node)
    if (overflowScrollReg.test(overflowY as string)) {
      if (node.tagName !== "BODY") {
        return node
      }

      const { overflowY: htmlOverflowY } = window.getComputedStyle(
        node.parentNode as Element
      )
      if (overflowScrollReg.test(htmlOverflowY as string)) {
        return node
      }
    }
    node = node.parentNode as HTMLElement
  }
  return rootParent
}

export function scrollBackTo(scrollTo) {
  const scrollDuration = 100
  const { performance, requestAnimationFrame } = window
  if (
    scrollDuration <= 0 ||
    typeof performance === "undefined" ||
    typeof requestAnimationFrame === "undefined"
  ) {
    return setScrollTop(scrollTo)
  }
  const start = performance.now()
  const initScrollTop =
    document.body.scrollTop ||
    (document.documentElement && document.documentElement.scrollTop) ||
    0
  const pxsToScrollBy = initScrollTop - scrollTo

  requestAnimationFrame(step)

  function step(timestamp) {
    const progress = Math.min((timestamp - start) / scrollDuration, 1)
    setScrollTop(initScrollTop - Math.round(progress * pxsToScrollBy))
    if (progress < 1) {
      requestAnimationFrame(step)
    }
  }
}

export function getScrollTop(element: ScrollElement): number {
  return "scrollTop" in element ? element.scrollTop : element.pageYOffset
}
