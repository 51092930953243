






























































































































































































import api, { MBbsContent } from "@/api/api"
import { Component, Vue, Watch } from "vue-property-decorator"
import { videoSortType } from "./extra"

enum sortType {
  create = "time.create:-1",
  reply = "time.reply:-1"
}

@Component({
  name: "ContentList",
  components: {
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    JinghuaList: () => import("./JinghuaList.vue"),
    QuesList: () => import("./QuesList.vue"),
    VideoCard: () => import("./VideoCard.vue"),
    VideoList: () => import("./VideoList.vue")
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== "communityArticleThread" &&
      to.name !== "article" &&
      to.name !== "question" &&
      to.name !== "video"
    ) {
      this.reset()
      from.meta.scrollTop = 0
    }
    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "bbsList") {
        vm["reset"]()
      }
    })
  }
})
export default class ContentList extends Vue {
  show = false
  get content() {
    return this.$root.$tstore.modules.content.getters.content
  }
  get sections() {
    return this.$root.$tstore.modules.bbs.getters.sections
  }

  hotIcon = require("@/assets/images/icon_section_heat.png")
  newIcon = require("@/assets/images/icon_section_new.png")
  moreIcon = require("@/assets/images/more.png")

  loading = false
  keyword = ""
  section_id = ""
  nextList = []

  reset() {
    this.$root.$tstore.modules.content.mutations.RESET_CONTENT()
    this.$root.$tstore.modules.content.mutations.RESET_JINGHUA()
    this.$root.$tstore.modules.content.mutations.RESET_VIDEO()
    this.$root.$tstore.modules.content.mutations.RESET_QUES()
    this.nextList = []
    this.type = 0
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }
  async activated() {
    if (this.content.data.length === 0) {
      await this.fetchData()
    }
    if (this.nextList.length === 0) {
      this.$root.$tstore.modules.content.mutations.SET_CONTENT({
        page: this.content.page + 1
      })
      this.getContentList()
    }
  }

  // 请求数据
  async fetchData() {
    this.loading = true
    return this.$root.$tstore.modules.content.actions
      .FETCH_CONTENT({
        id: this.$route.params.bbs_id
      })
      .finally(() => {
        this.loading = false
        this.$loading(false)
      })
  }

  /** 客户端请求数据 */
  async getContentList() {
    const res = await api.content.list(this.$route.params.bbs_id, {
      filter: `section_id:${this.section_id}`,
      sort: this.sort,
      type: "community_article|video|question",
      page: this.content.page,
      page_size: this.content.page_size
    })
    if (res.data.length === 0) {
      this.$root.$tstore.modules.content.mutations.SET_CONTENT({
        finished: true
      })
      this.nextList = []
    } else {
      this.nextList = res.data
    }
  }
  handleLoadMore() {
    this.$root.$tstore.modules.content.mutations.SET_CONTENT({
      data: this.content.data.concat(this.nextList),
      page: this.content.page + 1
    })
    this.getContentList()
  }

  sortType = sortType
  sort = sortType.reply

  @Watch("sort")
  async onSort(sort) {
    if (this.type === 2) return
    if (sort) {
      this.$root.$tstore.modules.content.mutations.RESET_CONTENT()
      this.nextList = []
      this.loading = true
      await this.getContentList()
      this.$root.$tstore.modules.content.mutations.SET_CONTENT({
        data: this.content.data.concat(this.nextList),
        page: this.content.page + 1
      })
      this.loading = false
      await this.getContentList()
    }
  }

  type = 0 //  0 全部  1 精华
  onType(c) {
    if (c === 0) {
      this.sort = sortType.reply
    }
    if (c === 2) {
      this.sort = sortType.create
    }
  }

  videoSortType = videoSortType
  videoSort = videoSortType.recommend

  createUrl(item: MBbsContent.IContent) {
    switch (item.type) {
      case "community_article":
        return `/bbs/thread-${item._seq || item._id}`
      case "question":
        return `/bbs/question-${item._seq || item._id}`
      case "video":
        return `/bbs/video-${item._seq || item._id}`
    }
    return ""
  }

  @Watch("section_id")
  async onSection(section_id) {
    if (this.show) {
      this.handleCloseAllSections()
    }
    this.$root.$tstore.modules.content.mutations.RESET_CONTENT()
    this.nextList = []
    this.loading = true
    await this.getContentList()
    this.$root.$tstore.modules.content.mutations.SET_CONTENT({
      data: this.content.data.concat(this.nextList),
      page: this.content.page + 1
    })
    this.loading = false
    await this.getContentList()
  }

  handleShowAllSections() {
    this.show = true
    this.$nextTick(() => {
      const el = this.$refs.sections as HTMLElement
      const elAll = this.$refs.allSections as HTMLElement
      const elMask = this.$refs.transparentMask as HTMLElement
      elAll.style.top = `${el.offsetTop}px`
      elAll.style.height = `${document.documentElement.scrollHeight}px`
      elMask.style.height = `${el.offsetTop}px`

      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    })
  }

  handleCloseAllSections() {
    this.show = false
    document.body.style.overflow = "auto"
    document.documentElement.style.overflow = "auto"
  }
}
