









/**
 * @description 加载更多
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "LoadingMore"
})
export default class HomeLoadingMore extends Vue {
  @Prop({ type: Boolean, default: false }) state!: boolean // 状态
  @Prop({ type: String, default: "展开全部" }) openName!: string // 名称
  @Prop({ type: String, default: "收起" }) closeName!: string // 名称

  openIcon: string = require("@/assets/images/icon-展开内容.png")
  closeIcon: string = require("@/assets/images/icon-收起内容.png")

  handleClick() {
    this.$emit("click")
  }
}
