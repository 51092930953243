































/**
 * @description 内容搜索页面
 */
import { Component, Vue, Watch } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "ContentSearch",
  components: {
    Footer: () => import("@/components/footer/footer.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    Result: () => import("./components/Result.vue"),
    SearchHeader: () => import("@/components/searchHeader/searchHeader.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue")
  },
  tdk() {
    return this.metaInfo
  }
})
export default class ContentSearch extends Vue {
  get metaInfo() {
    if (this.$route.params.keyword) {
      return {
        title: `${this.$route.params.keyword} - ${this.bbsName} 搜索结果 | 光环助手 要好玩，上光环！`,
        description: `光环助手 ${this.bbsName}论坛为您找到 ${this.list.length} 条搜索关键词相关帖子`,
        keywords: this.$route.params.keyword
      }
    }
    return {
      title: `${this.bbsName} - 玩家交流 | 光环助手 论坛`,
      description: `加入光环助手${this.bbsName}社区论坛，这里有优质详细的游戏攻略，精彩繁多的玩家视频，让你在热闹的游戏论坛找到志同道合的游戏伙伴。`,
      keywords: `${this.bbsName},攻略,论坛,社区,讨论,动态,公告`
    }
  }

  get bbsName() {
    return this.$store.state.bbs.bbsName
  }

  get contentSearchList() {
    return this.$store.state.bbs.contentSearchList
  }

  keyword: string = ""
  searchDone = false
  showFooter = false
  loading = false
  finished = false
  page = 1
  list = []
  isBlur = false

  // 监听router的replace，路由切换后读取url参数重写加载数据
  @Watch("$route")
  routerUpdate(to) {
    if (to.params.keyword) {
      // 切换路由数据清空
      this.list = []
      this.keyword = to.params.keyword
      this.fetchData(to.params.bbs_id).finally(() => {
        updateTdk.call(this)
      })
    } else {
      this.searchDone = false
      this.keyword = ""
    }
  }

  // ssr hook
  serverPrefetch() {
    return this.init()
  }

  beforeMount() {
    if (!this.contentSearchList) {
      this.init()
    } else {
      this.formatData()
    }
  }

  mounted() {
    this.listenAndroidDevice()
  }

  init() {
    const fetchs = []
    if (!this.bbsName) {
      fetchs.push(
        this.$store.dispatch("bbs/FETCH_BBS_NAME", {
          id: this.$route.params.bbs_id
        })
      )
    }
    let { keyword, bbs_id } = this.$route.params
    if (keyword) {
      this.keyword = keyword
      fetchs.push(this.fetchData(bbs_id))
    }
    return Promise.all(fetchs).finally(() => {
      updateTdk.call(this)
    })
  }

  formatData() {
    if (this.contentSearchList.length === 0) {
      this.finished = true
      return
    }
    this.list = this.list.concat(this.contentSearchList || [])
    this.searchDone = true
  }

  listenAndroidDevice() {
    if (this.$isServer) return
    let self = this
    //获取原窗口的高度
    let originalHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    window.onresize = function() {
      //键盘弹起与隐藏都会引起窗口的高度发生变化
      let resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      if (resizeHeight < originalHeight) {
        //当键盘弹起，在此处操作
      } else {
        //当键盘收起，在此处操作
        const dom: any = self.$refs.contentSearch
        dom.setBlur()
      }
    }
  }

  destroyed() {
    window.onresize = null
  }

  onLoad() {
    if (this.list.length === 0) {
      this.loading = false
      return
    }
    this.page++
    this.fetchData(this.$route.params.bbs_id)
  }

  handleClear() {
    this.keyword = ""
  }

  handleFocus() {
    if (this.$route.params.keyword) {
      this.showFooter = false
    }
  }

  handleBlur() {
    if (this.$route.params.keyword) {
      this.showFooter = true
    }
  }

  onTouchmove() {
    const dom: any = this.$refs.contentSearch
    dom.setBlur()
  }

  async handleSearch() {
    let { keyword, bbs_id } = this.$route.params
    if (this.keyword.length > 0 && keyword !== this.keyword) {
      this.$router.replace(`/bbs/${bbs_id}/search/${this.keyword}`)
    } else if (this.keyword.length > 0) {
      this.list = []
      this.fetchData(bbs_id)
    }
  }

  fetchData(id: number | string) {
    this.loading = true
    this.$loading(true)
    return this.$store
      .dispatch("bbs/FETCH_CONTENT_SEARCH", {
        keyword: this.keyword,
        type: "community_article",
        bbs_id: id,
        page: this.page
      })
      .then(this.formatData)
      .finally(() => {
        this.$loading(false)
        this.loading = false
      })
  }
}
