import { apiBaseUrlV5_5 } from "@/config"
import { communityArticle } from "@/store/modules/communityArticle"
import axios from "axios"

// https://jira.shanqu.cc/browse/GHZS-5049
export function useContentApi(token) {
  const service = axios.create({
    baseURL: apiBaseUrlV5_5,
    timeout: 30000,
    //baseURL: '/',
    headers: {
      "Content-Type": "application/json",
      token
    }
  })
  return {
    communityArticleDetail(article_id: string) {
      return service.get(`/communities/articles/${article_id}?view=detail`)
    },
    quesDetail(question_id: string) {
      return service.get(`/bbses/questions/${question_id}?view=detail`)
    },
    favorites: {
      communityArticle(_id: string) {
        return service.post(`/users/favorites/communities/articles/${_id}`)
      },
      question(_id: string) {
        return service.post(`/users/favorites/bbses/questions/${_id}`)
      },
      video(_id: string) {
        return service.post(`/users/favorites/videos/${_id}`)
      },
      article(_id: string) {
        return service.post(`/users/favorites/articles/${_id}`)
      }
    },
    cancel_favorites: {
      communityArticle(_id: string) {
        return service.delete(`/users/favorites/communities/articles/${_id}`)
      },
      question(_id: string) {
        return service.delete(`/users/favorites/bbses/questions/${_id}`)
      },
      video(_id: string) {
        return service.delete(`/users/favorites/videos/${_id}`)
      },
      article(_id: string) {
        return service.delete(`/users/favorites/articles/${_id}`)
      }
    },
    vote: {
      communityArticle(_id: string) {
        return service.post(`/communities/articles/${_id}:vote`)
      },
      video(_id: string) {
        return service.post(`/videos/${_id}:vote`)
      }
    },
    unvote: {
      communityArticle(_id: string) {
        return service.post(`/communities/articles/${_id}:unvote`)
      },
      video(_id: string) {
        return service.post(`/videos/${_id}:unvote`)
      }
    },
    comment: {
      communityArticle(_id: string, data: any) {
        return service.post(`/communities/articles/${_id}/comments`, data)
      },
      question(_id: string, data: any) {
        return service.post(`/bbses/questions/${_id}/comments`, data)
      },
      video(_id: string, data: any) {
        return service.post(`/videos/${_id}/comments`, data)
      },
      article(_id: string, data: any) {
        return service.post(`/articles/${_id}/comments`, data)
      }
    },
    reply_comment: {
      communityArticle(_id: string, data: any) {
        return service.post(`/communities/articles/comments/${_id}:reply`, data)
      },
      question(_id: string, data: any) {
        return service.post(`/bbses/questions/comments/${_id}:reply`, data)
      },
      video(_id: string, data: any) {
        return service.post(`/videos/comments/${_id}:reply`, data)
      },
      article(_id: string, data: any) {
        return service.post(`/articles/comments/${_id}:reply`, data)
      }
    },
    vote_comment: {
      communityArticle(_id: string) {
        return service.post(`/communities/articles/comments/${_id}:vote`)
      },
      question(_id: string) {
        return service.post(`/bbses/questions/comments/${_id}:vote`)
      },
      video(_id: string) {
        return service.post(`/videos/comments/${_id}:vote`)
      },
      article(_id: string) {
        return service.post(`/articles/comments/${_id}:vote`)
      }
    },
    unvote_comment: {
      communityArticle(_id: string) {
        return service.post(`/communities/articles/comments/${_id}:unvote`)
      },
      question(_id: string) {
        return service.post(`/bbses/questions/comments/${_id}:unvote`)
      },
      video(_id: string) {
        return service.post(`/videos/comments/${_id}:unvote`)
      }
    }
  }
}
