import api, { MHome } from "@/api/api"

interface IState {
  columns: MHome.IColumn[]
  tags: MHome.ITag[]
  contents: MHome.IContent[]
}

export const home = {
  namespaced: true,
  state: () => ({
    columns: null,
    tags: null,
    contents: null
  }),
  getters: {
    columns: (state: IState) => state.columns,
    tags: (state: IState) => state.tags,
    contents: (state: IState) => state.contents
  },
  mutations: {
    SET_COLUMNS: (state: IState, data) => {
      state.columns = data
    },
    SET_TAGS: (state: IState, data) => {
      state.tags = data
    },
    SET_CONTENTS: (state: IState, data) => {
      state.contents = data
    }
  },
  actions: {
    FETCH_HOMES: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await api.games.homeList()
          const { columns, contents, tags } = res.data

          commit("SET_COLUMNS", columns)
          commit("SET_TAGS", tags)
          commit("SET_CONTENTS", contents)

          resolve("")
        } catch (error) {
          console.log(error)
        }
      })
    }
  }
}
