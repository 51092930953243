import { urlScheme } from "@/utils/base"
import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(error => {})
// }

export default function createRouter() {
  const router = new Router({
    mode: "history",
    routes: [
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/404/404.vue")
      },
      {
        path: "/",
        component: () => import("@/views/Home/Home.vue"),
        children: [
          {
            path: "",
            name: "gameList",
            meta: {
              keepAlive: true
            },
            component: () => import("@/views/GameList/GameList.vue")
          },
          {
            path: "/game/:game_id",
            name: "game",
            component: () => import("@/views/Game/Game.vue")
          },
          {
            path: "/game/:game_id/comments",
            name: "gameComments",
            component: () => import("@/views/Game/Game.vue")
          },

          {
            path: "/bbs",
            name: "bbsList",
            meta: {
              keepAlive: true
            },
            component: () => import("@/views/BbsHome/BbsHome.vue")
          },
          {
            path: "/top",
            name: "tops",
            redirect: "/top/hot"
          },
          {
            path: "/top/:path",
            name: "top",
            meta: {
              keepAlive: true
            },
            component: () => import("@/views/Ranking/Ranking.vue")
          },
          {
            path: "/column",
            name: "columns",
            meta: {
              keepAlive: true
            },
            component: () => import("@/views/Novel/Novel.vue")
          },
          {
            path: "/toolkit",
            name: "toolkit",
            component: () => import("@/views/Toolkit/Toolkit.vue")
          },
          {
            path: "/:game_id(\\d+)",
            name: "gameNew",
            component: () => import("@/views/Game/Game.vue")
          },
          {
            path: "/:game_id/comments",
            name: "gameCommentsNew",
            component: () => import("@/views/Game/Game.vue")
          }
        ]
      },

      // 某游戏论坛
      {
        path: "/bbs/:bbs_id(\\d+)",
        component: () => import("@/views/Bbs/Bbs.vue"),
        meta: {
          keepAlive: true
        },
        children: [
          {
            path: "",
            name: "ContentList",
            meta: {
              keepAlive: true
            },
            component: () => import("@/views/Bbs/components/content.vue")
          }
        ]
      },
      // 论坛搜索页
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/Search/Search.vue")
      },
      {
        path: "/search/:keyword",
        name: "searchResult",
        // component: () => import("@/views/BbsSearch/BbsSearch.vue")
        component: () => import("@/views/Search/Search.vue")
      },
      // 资讯文章
      {
        path: "/article/:article_id",
        name: "article",
        component: () => import("@/views/Article/Article.vue")
      },
      // 社区文章
      {
        path: "/bbs/:bbs_id/article/:article_id",
        name: "communityArticle",
        component: () => import("@/views/CommunityArticle/CommunityArticle.vue")
      },
      {
        path: "/bbs/thread-:article_id",
        name: "communityArticleThread",
        component: () => import("@/views/CommunityArticle/CommunityArticle.vue")
      },
      {
        path: "/bbs/question-:ques_id",
        name: "question",
        component: () => import("@/views/Question/Question.vue")
      },

      // 视频帖
      {
        path: "/bbs/video-:video_id",
        name: "video",
        component: () => import("@/views/Video/Video.vue")
      },
      // 内容详情搜索页
      {
        path: "/bbs/:bbs_id/search",
        name: "contentSearch",
        component: () => import("@/views/ContentSearch/ContentSearch.vue")
      },
      {
        path: "/bbs/:bbs_id/search/:keyword",
        name: "contentSearchResult",
        component: () => import("@/views/ContentSearch/ContentSearch.vue")
      },

      {
        path: "/game/:game_id/comment/:comment_id",
        name: "comment",
        component: () => import("@/views/GameComment/GameComment.vue")
      },
      {
        path: "/tag/:tag_name",
        name: "tag",
        component: () => import("@/views/Tag/Tag.vue")
      },
      {
        path: "/toolkit/search",
        name: "toolkitSearch",
        component: () => import("@/views/Toolkit/components/search/search.vue")
      },
      {
        path: "/toolkit/search/:toolkitKeyword",
        name: "toolkitSearchResult",
        component: () => import("@/views/Toolkit/components/search/search.vue")
      },
      // 联系我们
      {
        path: "/contact",
        name: "contact",
        meta: {
          belong: "basic"
        },
        component: () => import("@/views/Contact/Contact.vue")
      },
      //地图
      {
        path: "/map",
        name: "map",
        meta: {
          belong: "basic"
        },
        component: () => import("@/views/Map/Map.vue")
      },
      //精选内容
      {
        path: "/jxwd",
        name: "jxwd",
        meta: {
          belong: "basic"
        },
        component: () => import("@/views/JXWD/JXWD.vue")
      },
      {
        path: "/column/:column_id",
        name: "column",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/Column/Column.vue")
      },
      {
        path: "/test",
        name: "test",
        component: () => import("@/views/Test/Test.vue")
      },
      {
        path: "/:game_id/comment/:comment_id",
        name: "commentNew",
        component: () => import("@/views/GameComment/GameComment.vue")
      },
      {
        path: "/juhe",
        name: "juhe",
        component: () => import("@/views/Juhe/Juhe.vue")
      }
    ],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
  if (!process.env.isSSR) {
    router.afterEach(route => {
      switch (route.name) {
        case "bbsList":
          window._launchAppUrl = "ghzhushou://forum"
          break
        case "ContentList":
          window._launchAppUrl = urlScheme.bbs(route.params.bbs_id)
          break
        case "communityArticle":
          window._launchAppUrl = urlScheme.communityArticle(
            route.params.article_id,
            route.params.bbs_id
          )
          break
        case "game":
          window._launchAppUrl = urlScheme.game(route.params.game_id, "desc")
          break
        case "gameComments":
          window._launchAppUrl = urlScheme.game(route.params.game_id, "comment")
          break
        case "comment":
          window._launchAppUrl = urlScheme.gameComment(
            route.params.game_id,
            route.params.comment_id
          )
          break
      }
    })
  }
  return router
}
