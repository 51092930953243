



























import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { videoSortType } from "./extra"
import api from "@/api/api"
@Component({
  name: "VideoList",
  beforeRouteLeave(to, from, next) {
    // this.$root.$tstore.modules.content.mutations.RESET_VIDEO()
    next()
  },
  components: {
    VideoCard: () => import("./VideoCard.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    Img: () => import("@/components/image/image.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  }
})
export default class Content extends Vue {
  @Prop({ type: String, default: "" }) sort: videoSortType

  get bbs() {
    return this.$store.state.bbs.bbs
  }
  get video() {
    return this.$root.$tstore.modules.content.getters.video
  }

  loading = false

  nextList = []

  beforeMount() {
    if (this.video.data.length === 0) {
      this.getList()
    }
  }

  /** 客户端请求数据 */
  async getList() {
    this.loading = true
    const res = await api.content.videoList(this.$route.params.bbs_id, {
      sort: this.sort,
      page: this.video.page,
      page_size: this.video.page_size
    })

    if (res.data.length > 0) {
      this.$root.$tstore.modules.content.mutations.SET_VIDEO({
        data: this.video.data.concat(res.data),
        page: this.video.page + 1
      })
      this.prefetchNextPage()
    } else {
      this.$root.$tstore.modules.content.mutations.SET_VIDEO({
        finished: true
      })
    }
    this.loading = false
  }

  handleLoadMore() {
    this.$root.$tstore.modules.content.mutations.SET_VIDEO({
      data: this.video.data.concat(this.nextList),
      page: this.video.page + 1
    })
    this.prefetchNextPage()
  }

  async prefetchNextPage() {
    const nextRes = await api.content.videoList(this.$route.params.bbs_id, {
      sort: this.sort,
      page: this.video.page,
      page_size: this.video.page_size
    })
    if (nextRes.data.length === 0) {
      this.$root.$tstore.modules.content.mutations.SET_VIDEO({
        finished: true
      })
      this.nextList = []
    } else {
      this.nextList = nextRes.data
    }
  }

  createUrl(id) {
    return `/bbs/video-${id}`
  }

  @Watch("sort")
  async onSort(_: videoSortType) {
    if (_) {
      this.$root.$tstore.modules.content.mutations.RESET_VIDEO()
      this.nextList = []
      this.loading = true
      await this.getList()
      this.$root.$tstore.modules.content.mutations.SET_VIDEO({
        data: this.video.data.concat(this.nextList),
        page: this.video.page + 1
      })
      this.loading = false
    }
  }
}
