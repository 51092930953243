import { apiBaseUrlV5_5 } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"
import { IListParams } from "../api"
import { getUserToken, inPlugin, checkPluginLogin } from "@/utils/base"

export default function(service: AxiosInstance) {
  return {
    video: {
      // 视频帖详情
      detail(video_id: string): Promise<AxiosResponse<NVideo.IDetail>> {
        let config = {}
        if (inPlugin() && checkPluginLogin()) {
          config["headers"] = {
            token: getUserToken()
          }
        }
        return service.get(`/bbses/videos/${video_id}?view=web`, config)
      },
      // 获取游戏视频的评论列表
      comments(
        video_id: string,
        params: IListParams
      ): Promise<AxiosResponse<NVideoComment.IItem[]>> {
        let headers = {}
        if (inPlugin() && checkPluginLogin()) {
          headers = {
            token: getUserToken()
          }
        }
        return service.get(
          `${apiBaseUrlV5_5}/videos/${video_id}/comments?view=web&attached_limit=3`,
          {
            params,
            headers
          }
        )
      },
      // 获取游戏视频的评论回复
      commentsReplys(
        video_id: string | number,
        comment_id: string | number
      ): Promise<AxiosResponse<NVideoComment.IItem[]>> {
        return service.get(
          `${apiBaseUrlV5_5}/videos/${video_id}/comments/${comment_id}/replies`
        )
      },
      // 视频-相关/推荐视频
      recommends(
        video_id: string
      ): Promise<AxiosResponse<NVideoRecommend.IItem[]>> {
        return service.get(`/bbses/videos/${video_id}/recommends?page_size=10`)
      }
    }
  }
}

interface IList {
  page?: number
  page_size?: number
  view?: string
  filter?: string
  keyword?: string
  active?: boolean
}

export interface IToolkitList {
  _id: string
  name: string
  total: number
  data: Datum[]
}
export interface Datum {
  _id: string
  icon: string
  name: string
  des: string
  url: string
  time: number
}

export namespace NVideo {
  export interface IDetail {
    _id: string
    title: string
    des: string
    poster: string
    url: string
    length: number
    type: string
    bbs_id: string
    vote: number
    comment_count: number
    favorite: number
    share: number
    user: IUser
    status: string
    time: ITime
    bbs: IBb
    game: IGame
    game_id?: string
    tag_activity_id: string
    tag_activity_name: string
    video_info: IVideo_info
    choiceness_status: string
    me?: IMe
  }

  interface IMe {
    is_follower: boolean
    is_video_favorite: boolean
    is_voted: boolean
  }

  interface IVideo_info {
    width: number
    height: number
  }

  interface IGame {
    _id: string
    name: string
    name_suffix: string
    category: string
    brief: string
    icon: string
    ori_icon: string
    type_tag: void /* undefined */[]
    download: number
    show_comment: boolean
    direct_comment: boolean
    ignore_comment: boolean
    comment_description: string
    mirror_status: string
    comment_count: number
    version_number: string
    new_star: number
    tag: void /* undefined */[]
    download_off_text: string
    apk_normal: void /* undefined */[]
    apk: void /* undefined */[]
    d_button_add_word: string
    p_button_add_word: string
    concern_article_exists: boolean
    libao_exists: boolean
    zone_setting: IZone_setting
    _seq?: number
  }

  interface IZone_setting {
    status: string
    style: string
  }

  interface IBb {
    _id: string
    name: string
    game_id: string
    _seq: string
    icon: string
  }

  interface ITime {
    upload: number
    audit: number
    update: number
  }

  interface IUser {
    _id: string
    name: string
    icon: string
    icon_border_url: string
    badge: IBadge
    count: ICount
  }

  interface ICount {
    fans: number
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }
}

export namespace NVideoComment {
  export interface IItem {
    _id: string
    content: string
    user: IUser
    time: number
    me: IMe
    attached: IAttached[]
    reply: number
    is_top?: boolean
    images?: string[]
    vote: number
    _seq: number
  }

  interface IAttached {
    _id: string
    video_id: string
    game_id: string
    content: string
    active: boolean
    type: string
    user: IAttachedUser
    trace: string[]
    vote: number
    time: number
    operator: IAttachedOperator
    priority: number
    _seq: number
    me: IAttachedMe
  }

  interface IAttachedMe {
    is_video_own: boolean
  }

  interface IAttachedOperator {
    creator: string
    modifier: string
  }

  interface IAttachedUser {
    _id: string
    name: string
    icon: string
    badge: IBadge
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }
  interface IMe {
    is_video_commented: boolean
    is_video_comment_voted: boolean
    is_video_own: boolean
  }

  interface IUser {
    _id: string
    icon: string
    name: string
    auth: IAuth
    badge?: IBadge
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }

  interface IAuth {
    text: string
    color: string
    icon: string
  }
}

export namespace NVideoRecommend {
  export interface IItem {
    length: number
    title: string
    url: string
    des: string
    poster: string
    game_id: string
    _id: string
    user: IUser
    count: ICount
    _seq: number
    time_len: string
  }

  interface ICount {
    vote: number
    comment: number
    favorite: number
  }

  interface IUser {
    _id: string
    name: string
    icon: string
  }
}
