import { render, staticRenderFns } from "./content.vue?vue&type=template&id=8cdef1de"
import script from "./content.vue?vue&type=script&lang=ts"
export * from "./content.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./content.vue?vue&type=style&index=0&id=8cdef1de&prod&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "f8d2322c"
  
)

export default component.exports