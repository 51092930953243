










/**
 * @description 未找到数据
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "NotFoundData"
})
export default class NotFoundData extends Vue {
  @Prop({
    type: String,
    default: require("@/assets/images/缺省插图-无搜索结果.png")
  })
  icon!: string

  @Prop({ type: String, default: "找不到符合的内容" }) title!: string
  @Prop({ type: String, default: "换个关键词试试～" }) desc!: string
}
