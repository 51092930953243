import { createApp } from "./main"

const { app, router, $tstore } = createApp()

if (window.__INITIAL_STATE__) {
  $tstore.store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
  app.$mount("#app")
})
