











/**
 * @description 首页头部
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "HomeHeader"
})
export default class HomeHeader extends Vue {
  @Prop({ type: String, default: "push" }) routerType!: string // 路由跳转类型

  titleIcon: string = require("@/assets/images/logo-一级导航栏.png")
  searchIcon: string = require("@/assets/images/icon-搜索-暗.png")

  handleSearch() {
    this.$router[this.routerType]("/search")
    this.$emit("handleSearch")
  }

  handleLogo() {
    this.$router.push("/")
  }
}
