import Api from "@/api/api"

export const position = {
  namespaced: true,
  state: () => ({
    game: 0,
    gameDetail: 0,
    bbs: 0
  }),
  getters: {
    bbs: state => state.bbs
  },
  mutations: {
    SAVE_GAME: (state, game) => {
      state.game = game
    },
    SAVE_GAME_DETAIL: (state, gameDetail) => {
      state.gameDetail = gameDetail
    },
    SAVE_BBS: (state, bbs) => {
      state.bbs = bbs
    }
  },
  actions: {}
}
