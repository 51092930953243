import { render, staticRenderFns } from "./Novel.vue?vue&type=template&id=88629a10&scoped=true"
import script from "./Novel.vue?vue&type=script&lang=ts"
export * from "./Novel.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Novel.vue?vue&type=style&index=0&id=88629a10&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "88629a10",
  "f9bf9e1e"
  
)

export default component.exports