import { Datum, IToolkitList } from "@/api/modules/toolkit"
import { ActionContext } from "vuex"
import Api from "@/api/api"

export const toolkit = {
  namespaced: true,
  state: () => ({
    list: null,
    searchList: null
  }),
  getters: {
    list: state => state.list,
    searchList: state => state.searchList
  },
  mutations: {
    SET_LIST: (state, { list }) => {
      state.list = list
    },
    SET_SEARCH_LIST: (state, { searchList }) => {
      state.searchList = searchList
    }
  },
  actions: {
    FETCH_LIST: ({ commit }) => {
      return Api.toolkit
        .list({ view: "category" })
        .then(res => commit("SET_LIST", { list: res.data }))
    },
    FETCH_SEARCH_LIST: ({ commit }, { keyword }) => {
      return Api.toolkit
        .listByKeyword(keyword)
        .then(res => commit("SET_SEARCH_LIST", { searchList: res.data }))
    }
  }
}
