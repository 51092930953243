import { render, staticRenderFns } from "./BbsSearch.vue?vue&type=template&id=a1a0de8a&scoped=true"
import script from "./BbsSearch.vue?vue&type=script&lang=ts"
export * from "./BbsSearch.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./BbsSearch.vue?vue&type=style&index=0&id=a1a0de8a&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a1a0de8a",
  "2f246d46"
  
)

export default component.exports