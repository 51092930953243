






























import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "downloadDialog"
})
export default class downloadDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    gameName: "",
    tags: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  tag = ""
  handleTag(name) {
    this.tag = name
    this.onSubmit()
  }
}
