





































import { INovelColumnItemData } from "@/api/modules/novel"
import { Component, Vue, Prop } from "vue-property-decorator"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import SwiperCore, { Pagination, Navigation } from "swiper"
SwiperCore.use([Pagination, Navigation])
// import style
// @ts-ignore
import("swiper/swiper-bundle.css")
@Component({
  name: "NovelColumn",
  components: { Swiper, SwiperSlide }
})
export default class NovelColumn extends Vue {
  @Prop({ type: Array, default: () => [] }) list: INovelColumnItemData[]
  @Prop({ type: [String, Number], default: "" }) column_id: string
  @Prop({ type: String, default: "" }) column_name: string

  swiperOptions = {
    slidesPerView: 4,
    freeMode: true
  }
  mounted() {}
}
