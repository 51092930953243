



















































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
import api from "@/api/api"

@Component({
  name: "BbsHome",
  components: {
    Header: () => import("./components/Header.vue"),
    More: () => import("./components/LoadingMore.vue"),
    Footer: () => import("@/components/footer/footer.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    HaloImg: () => import("@/components/image/image.vue"),
    OfficialBbsList: () => import("./components/OfficialBbsList.vue"),
    HotBbsList: () => import("./components/HotBbsList.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue")
  },
  tdk() {
    return this.$store.state.tdk
  },
  beforeRouteLeave(to, from, next) {
    this.saveScollTop()
    next()
  }
})
export default class BbsHome extends Vue {
  saveScollTop() {
    if (!this.$isServer) {
      const homeMain = document.querySelector(".home-main")
      if (homeMain) {
        let position = homeMain.scrollTop
        this.$store.commit("position/SAVE_BBS", position) //离开路由时把位置存起来
      }
    }
  }
  get oldScrollTop() {
    return this.$root.$tstore.modules.position.getters.bbs
  }

  tab = "0"
  onTab() {
    document.querySelector(".home-main").scrollTo(0, 0)
    this.saveScollTop()
  }

  // 热帖
  get recommendContents() {
    return this.$root.$tstore.modules.bbs.getters.recommendContents
  }

  list: Array<any> = [] // 论坛列表

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  //展开更多
  handleShowMore(index: number) {
    this.list[index].showAllState = !this.list[index].showAllState
  }

  async fetchData() {
    this.$loading(true)
    return Promise.all([
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "manual",
        _id_or_name: encodeURI("论坛集合页")
      }),
      this.$root.$tstore.modules.bbs.actions.FETCH_RECOMMEND_CONTENTS()
    ])
      .then(() => {
        this.checkNextPage()

        this.setTDKCache()
      })
      .catch(err => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        updateTdk.call(this)
        this.$loading(false)
      })
  }

  /** tdk */
  mounted() {
    this.checkTDK()
  }
  setTDKCache() {
    const { title, keyword, description } = this.$store.state.tdk
    this.$store.commit("tdk/SET_CACHE", {
      path: this.$route.fullPath,
      tdk: JSON.stringify({ title, keyword, description })
    })
  }
  checkTDK() {
    if (this.$store.state.tdk.caches[this.$route.fullPath]) {
      const tdk = JSON.parse(this.$store.state.tdk.caches[this.$route.fullPath])
      this.$store.commit("tdk/SET_TDK", tdk)
      updateTdk.call(this)
    }
  }

  createUrl(item) {
    return `/bbs/thread-${item._seq || item._id}`
  }

  activated() {
    document.querySelector(".home-main").scrollTo(0, this.oldScrollTop)
  }

  /** 客户端请求数据 */
  nextList = []

  handleLoadMore() {
    this.$root.$tstore.modules.bbs.mutations.SET_RECOMMEND_CONTENTS({
      data: this.recommendContents.data.concat(this.nextList),
      page: this.recommendContents.page + 1
    })
    this.getContentList()
  }
  checkNextPage() {
    if (this.nextList.length === 0) {
      this.$root.$tstore.modules.bbs.mutations.SET_RECOMMEND_CONTENTS({
        page: this.recommendContents.page + 1
      })
      this.getContentList()
    }
  }
  async getContentList() {
    const nextRes = await api.bbs.recommendContents({
      page: this.recommendContents.page,
      page_size: this.recommendContents.page_size,
      sort: "time.comment:-1"
    })
    if (nextRes.data.length === 0) {
      this.$root.$tstore.modules.bbs.mutations.SET_RECOMMEND_CONTENTS({
        finished: true
      })
      this.nextList = []
    } else {
      this.nextList = nextRes.data
    }
  }
}
