





































/**
 * @description 搜索结果数据列表
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "ContentSearchResult",
  components: {
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue")
  }
})
export default class ContentSearchResult extends Vue {
  @Prop({ type: String, default: "内容搜索结果" }) title!: string // 搜索结果标题
  @Prop({ type: Array, default: () => [] }) list!: any // 数据列表
}
