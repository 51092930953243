




































































import { MBbsContent } from "@/api/api"
import { timeago } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "VideoCard",
  components: {}
})
export default class VideoCard extends Vue {
  @Prop({ type: Object, default: {} }) data: MBbsContent.IVideo

  $refs: { dplayerVideo: any; videoWrapper: HTMLElement }

  timeago = timeago

  mounted() {}
}
