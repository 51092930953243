import { render, staticRenderFns } from "./CustomColumn.vue?vue&type=template&id=0060e674&scoped=true"
import script from "./CustomColumn.vue?vue&type=script&lang=ts"
export * from "./CustomColumn.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./CustomColumn.vue?vue&type=style&index=0&id=0060e674&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0060e674",
  "2ac98860"
  
)

export default component.exports