import api, { MBbsContent } from "@/api/api"
import { NVideo, NVideoComment, NVideoRecommend } from "@/api/modules/video"
import { formatSeconds, ITableData, TableData } from "@/utils/base"

interface IState {
  content: ITableData<MBbsContent.IContent>
  jinghua: ITableData<MBbsContent.IContent>
  ques: ITableData<MBbsContent.IQues>
  video: ITableData<MBbsContent.IVideoListItem>
  videoDetail: NVideo.IDetail
  videoComments: ITableData<NVideoComment.IItem>
  videoRecommends: NVideoRecommend.IItem[]
}

export const content = {
  namespaced: true,
  state: () => ({
    contentList: null,
    jinghuaList: null,
    ques: new TableData({}),
    content: new TableData({}),
    jinghua: new TableData({}),
    video: new TableData({}),
    videoDetail: null,
    videoComments: new TableData({}),
    videoRecommends: null
  }),
  getters: {
    content: (state: IState) => state.content,
    jinghua: (state: IState) => state.jinghua,
    ques: (state: IState) => state.ques,
    video: (state: IState) => state.video,
    videoDetail: (state: IState) => state.videoDetail,
    videoRecommends: (state: IState) => state.videoRecommends,
    videoComments: (state: IState) => state.videoComments
  },
  mutations: {
    SET_CONTENT_LIST: (state, { contentList }) => {
      state.contentList = contentList
    },
    SET_JINGHUA_LIST: (state, { jinghuaList }) => {
      state.jinghuaList = jinghuaList
    },

    SET_CONTENT: (state, data: IState["content"]) => {
      state.content = Object.assign(state.content, data)
    },
    RESET_CONTENT: state => {
      state.content = new TableData({})
    },
    SET_JINGHUA: (state, data: IState["jinghua"]) => {
      state.jinghua = Object.assign(state.jinghua, data)
    },
    RESET_JINGHUA: state => {
      state.jinghua = new TableData({})
    },
    SET_QUES: (state, data: IState["ques"]) => {
      state.ques = Object.assign(state.ques, data)
    },
    RESET_QUES: state => {
      state.ques = new TableData({})
    },
    SET_VIDEO: (state, data: IState["video"]) => {
      state.video = Object.assign(state.video, data)
    },
    RESET_VIDEO: state => {
      state.video = new TableData({})
    },
    SET_VIDEO_DETAIL: (state, data: IState["videoDetail"]) => {
      state.videoDetail = data
    },
    SET_VIDEO_COMMENTS: (state, data: IState["videoComments"]) => {
      state.videoComments = Object.assign(state.videoComments, data)
    },
    RESET_VIDEO_COMMENTS: state => {
      state.videoComments = new TableData({})
    },
    SET_VIDEO_COMMENTS_REPLYS: (state, { idx, replys }) => {
      state.videoComments.data[idx].attached = replys
    },
    SET_VIDEO_RECOMMENDS: (state, data: IState["videoRecommends"]) => {
      state.videoRecommends = data
    }
  },
  actions: {
    FETCH_CONTENT_LIST: ({ commit }, { id, params }) => {
      return api.content
        .list(id, params)
        .then(res => commit("SET_CONTENT_LIST", { contentList: res.data }))
    },
    FETCH_JINGHUA_LIST: ({ commit }, { id, params }) => {
      return api.content
        .jinghuaList(id, params)
        .then(res => commit("SET_JINGHUA_LIST", { jinghuaList: res.data }))
    },

    FETCH_CONTENT: ({ commit }, { id }) => {
      return api.content
        .list(id, {
          sort: "time.reply:-1",
          page: 1,
          page_size: 20,
          type: "community_article|video|question"
        })
        .then(res => commit("SET_CONTENT", { data: res.data }))
    },
    FETCH_VIDEO_DETAIL: ({ commit }, { id }: { id: string }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await api.video.detail(id)
          commit("SET_VIDEO_DETAIL", res.data)

          const resComment = await api.video.comments(id, {
            page: 1,
            page_size: 30,
            sort: "time.create:-1"
          })
          commit("SET_VIDEO_COMMENTS", {
            data: resComment.data,
            page: 2,
            page_size: 30,
            finished: resComment.data.length < 30,
            total: resComment.headers.total || 0
          })

          const resRecommend = await api.video.recommends(id)
          resRecommend.data.forEach(v => {
            v.time_len = formatSeconds(v.length)
          })
          commit("SET_VIDEO_RECOMMENDS", resRecommend.data)

          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
