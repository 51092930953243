
























































































import api from "@/api/api"
import { timeago, urlScheme, formatURLInHTML } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "ReplyList",
  components: {
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  }
})
export default class ReplyList extends Vue {
  formatURLInHTML = formatURLInHTML
  urlScheme = urlScheme

  @Prop()
  replyNum: number

  list = []
  page = 1
  sort = "1" // 1 -1
  loading = false
  finished = false
  get getParams() {
    let params = {
      page: this.page,
      page_size: 30,
      sort: `time:${this.sort}`
    }
    return params
  }
  nextList = []
  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    const res = await api.games.gameCommentReplies(
      this.$route.params.game_id,
      this.$route.params.comment_id,
      {
        page: this.page,
        page_size: 30,
        sort: `time:${this.sort}`
      }
    )
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          v.timeago = timeago(v.time * 1000)
          return v
        })
      )
      this.prefetchNextPage()
    }
  }
  async prefetchNextPage() {
    const nextRes = await api.games.gameCommentReplies(
      this.$route.params.game_id,
      this.$route.params.comment_id,
      {
        page: this.page + 1,
        page_size: 30,
        sort: `time:${this.sort}`
      }
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        v.timeago = timeago(v.time * 1000)
        return v
      })
    }
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (!this.$store.state.gameList.gameCommentReplies) {
      this.fetchData().then(this.fetchDataMounted)
    } else {
      this.fetchDataMounted()
    }
  }
  destroyed() {
    this.$store.commit("gameList/SET_GAME_COMMENT_REPLIES", {
      gameCommentReplies: null
    })
  }

  fetchData() {
    this.loading = true
    return this.$store
      .dispatch("gameList/FETCH_GAME_COMMENT_REPLIES", {
        game_id: this.$route.params.game_id,
        comment_id: this.$route.params.comment_id,
        params: this.getParams
      })
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        this.loading = false
      })
  }

  fetchDataMounted() {
    if (
      this.$store.state.gameList.gameCommentReplies &&
      this.$store.state.gameList.gameCommentReplies.length === 0
    ) {
      this.finished = true
      return
    }
    this.list = this.list.concat(
      this.$store.state.gameList.gameCommentReplies.map(v => {
        v.timeago = timeago(v.time * 1000)
        return v
      })
    )
    this.prefetchNextPage()
  }

  @Watch("sort")
  onSort() {
    this.page = 1
    this.list = []
    this.nextList = []
    this.finished = false
    this.fetchData().then(this.fetchDataMounted)
  }
}
