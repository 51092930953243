





















import { Component, Vue, Prop } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
import api from "@/api/api"
@Component({
  name: "GameList",
  beforeRouteLeave(to, from, next) {
    this.saveScollTop()
    next()
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.$nextTick(() => {
        if (vm.scroll) {
          vm.$parent.$refs.homeMain.scrollTop = vm.scroll
        }
      })
    })
  },
  components: {
    GameCard: () => import("./components/GameCard/GameCard.vue"),
    ColumnCard: () => import("./components/ColumnCard/ColumnCard.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class GameList extends Vue {
  scroll = 0

  saveScollTop() {
    if (!this.$isServer && this.$parent && this.$parent.$refs.homeMain) {
      this.scroll = (this.$parent.$refs.homeMain as Element).scrollTop || 0
    }
  }

  get gameList() {
    return this.$root.$tstore.modules.gameList.getters.list
  }
  get filterTags() {
    return this.$root.$tstore.modules.gameList.getters.filterTags
  }
  get columns() {
    return this.$root.$tstore.modules.home.getters.columns || []
  }

  list = []
  page = 1
  loading = false
  finished = false

  // ssr hook
  async serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.$store.state.gameList.list) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  fetchData(page = 1, random?: string) {
    this.loading = true
    let params: any = { page }
    if (random) {
      params.random = random
    }

    return Promise.all([
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "manual",
        _id_or_name: encodeURI("首页")
      }),
      this.$store.dispatch("gameList/FETCH_LIST", params),
      this.$store.dispatch("gameList/FETCH_FILTER_TAGS"),
      this.$root.$tstore.modules.home.actions.FETCH_HOMES()
    ])
      .then(() => {
        this.formatData()

        this.setTDKCache()
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.loading = false
        updateTdk.call(this)
      })
  }

  formatData() {
    if (this.$store.state.gameList.list.length === 0) {
      this.finished = true
    }
    this.list = [...this.columns, ...this.list].concat(
      this.gameList.map(v => {
        if (v.link_game.new_tag_style) {
          v.link_game.new_tag_style = v.link_game.new_tag_style.filter(
            tag => !this.filterTags.includes(tag.name)
          )
        }
        return v
      })
    )

    // this.columns &&
    //   this.columns.forEach((item, idx) => {
    //     this.list.splice(2 * idx + 1, 0, item)
    //   })

    this.prefetchNextPage()
  }
  nextList = []
  async loadMoreData(page = 1, random?: string) {
    this.loading = true
    let params: any = { page: this.page, page_size: 15, view: "web" }

    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }

    const res = await api.games.homeContentList(params)
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          if (v.link_game.new_tag_style) {
            v.link_game.new_tag_style = v.link_game.new_tag_style.filter(
              tag => !this.$store.state.gameList.filterTags.includes(tag.name)
            )
          }
          return v
        })
      )
      this.prefetchNextPage()
      this.loading = false
    }
  }
  handleLoadMore() {
    this.page++
    this.loadMoreData(this.page)
  }
  async prefetchNextPage() {
    const nextRes = await api.games.homeContentList({
      page: this.page + 1,
      page_size: 15,
      view: "web"
    })
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        if (v.link_game.new_tag_style) {
          v.link_game.new_tag_style = v.link_game.new_tag_style.filter(
            tag => !this.$store.state.gameList.filterTags.includes(tag.name)
          )
        }
        return v
      })
    }
  }

  /** tdk */
  activated() {
    this.checkTDK()
  }
  setTDKCache() {
    const { title, keyword, description } = this.$store.state.tdk
    this.$store.commit("tdk/SET_CACHE", {
      path: this.$route.fullPath,
      tdk: JSON.stringify({ title, keyword, description })
    })
  }
  checkTDK() {
    if (this.$store.state.tdk.caches[this.$route.fullPath]) {
      const tdk = JSON.parse(this.$store.state.tdk.caches[this.$route.fullPath])
      this.$store.commit("tdk/SET_TDK", tdk)
      updateTdk.call(this)
    }
  }
}
