






















































import api from "@/api/api"
import { DateFotmat, urlScheme } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "Libao",
  components: {}
})
export default class Libao extends Vue {
  urlScheme = urlScheme

  @Prop({ type: Array, default: () => [] })
  libaos: any[]

  DateFotmat = DateFotmat

  statusTextMap = new Map([
    ["coming", "未开抢"],
    ["ling", "领取"],
    ["tao", "淘号"],
    ["check", "查看"],
    ["used_up", "已领光"]
  ])

  libaosData = []

  async getStatus() {
    const res = await api.libao.getStatus({
      filter: `libao_ids:${this.libaosData.map(v => v._id).join("-")}`
    })
    res.data.forEach(item => {
      this.libaosData.forEach(libao => {
        if (libao._id === item._id) {
          this.$set(libao, "text", this.statusTextMap.get(item.status))
          this.$set(libao, "status", item.status)
          this.$set(
            libao,
            "progress",
            Math.floor((item.available / item.total) * 100)
          )
        }
      })
    })
  }

  beforeMount() {
    if (this.libaos) {
      this.libaosData = JSON.parse(JSON.stringify(this.libaos))
      this.getStatus()
    }
  }
}
