import { render, staticRenderFns } from "./BbsHome.vue?vue&type=template&id=0b1943a2&scoped=true"
import script from "./BbsHome.vue?vue&type=script&lang=ts"
export * from "./BbsHome.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./BbsHome.vue?vue&type=style&index=0&id=0b1943a2&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0b1943a2",
  "6fcbcec6"
  
)

export default component.exports