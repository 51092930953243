import { render, staticRenderFns } from "./VideoCard.vue?vue&type=template&id=f834a34a&scoped=true"
import script from "./VideoCard.vue?vue&type=script&lang=ts"
export * from "./VideoCard.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./VideoCard.vue?vue&type=style&index=0&id=f834a34a&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f834a34a",
  "6884fdf6"
  
)

export default component.exports