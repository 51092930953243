




































import { NVideoRecommend } from "@/api/modules/video"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Recommends"
})
export default class Recommends extends Vue {
  @Prop({ type: Array, default: () => [] }) data: NVideoRecommend.IItem[]
}
