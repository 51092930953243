












import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "GameItem",
  components: {}
})
export default class GameItem extends Vue {
  @Prop({})
  link: string

  @Prop({})
  title: string

  @Prop({})
  time: string
}
