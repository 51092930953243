











import { Component, Vue, Prop, Watch } from "vue-property-decorator"

interface IRecommend {
  image: string
  title: string
  type: string
  link: string
  text: string
}

@Component({
  name: "RelatedVersion",
  components: {}
})
export default class RelatedVersion extends Vue {
  @Prop({ type: Object, default: () => [] })
  recommend: IRecommend
}
