













































































import { formatURLInHTML, timeago, urlScheme } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Rating from "./components/Rating/Rating.vue"
import updateTdk, { formatTDK } from "@/utils/tdk"
import api from "@/api/api"

type TSort = "time" | "vote" | ""

@Component({
  name: "GameComments",
  components: {
    Rating,
    CommentCard: () => import("./components/CommentCard/CommentCard.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class GameComments extends Vue {
  formatURLInHTML = formatURLInHTML
  urlScheme = urlScheme

  expand = {
    down: require("@/assets/images/icon_down.png"),
    up: require("@/assets/images/icon_up.png")
  }

  starIcon = {
    icon: require("@/assets/images/anli_Star_gray.png"),
    activeIcon: require("@/assets/images/anli_Star_blue.png")
  }

  config = {
    rows: 4,
    btnText: "全部",
    ellipsisText: "...",
    ellipsis: true
  }
  handleHide(item) {
    item.ellipsis = false
  }

  page = 1
  loading = false
  finished = false
  list = []
  star = 0
  sort: TSort = ""
  get getSortName() {
    let name = "默认"
    if (this.sort === "time") {
      name = "最新"
    }
    if (this.sort === "vote") {
      name = "最热"
    }
    return name
  }

  showStarOpts = false
  starOpts = [
    { label: "全部评论", value: 0 },
    { label: "1星", value: 1 },
    { label: "2星", value: 2 },
    { label: "3星", value: 3 },
    { label: "4星", value: 4 },
    { label: "5星", value: 5 }
  ]

  showSortOpts = false
  sortOpts: { label: string; value: TSort }[] = [
    { label: "默认", value: "" },
    { label: "最近", value: "time" },
    { label: "最热", value: "vote" }
  ]

  get getParams() {
    let params = {
      page: this.page,
      page_size: 30
    }

    if (this.star) {
      params["filter"] = `star:${this.star}`
    }

    if (this.sort) {
      params["sort"] = `${this.sort}:-1`
    }

    return params
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.$store.state.gameList.gameComments) {
      this.fetchData()
    } else {
      this.formatData()
    }
    this.prefetchNextPage()
  }
  destroyed() {
    this.$store.commit("gameList/SET_GAME_COMMENTS", { gameComments: null })
  }

  fetchData() {
    this.loading = true
    return Promise.all([
      this.$store.dispatch("gameList/FETCH_GAME_COMMENTS", {
        game_id: this.$route.params.game_id,
        params: this.getParams
      }),
      this.$store.dispatch("tdk/FETCH_SUB_TDK", {
        type: "game",
        _id_or_name: this.$route.params.game_id,
        sub_type: "comment"
      })
    ])
      .then(() => {
        this.list = []
        this.formatData()
        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.$store.state.gameList.gameAbstract.name
            }
          ])
        )
      })
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        this.loading = false
        updateTdk.call(this)
      })
  }

  formatData() {
    if (this.$store.state.gameList.gameComments.length === 0) {
      this.finished = true
      return
    }
    this.list = this.list.concat(
      this.$store.state.gameList.gameComments.map(v => {
        v.timeago = timeago(v.time * 1000)
        return v
      })
    )
  }
  nextList = []
  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    const res = await api.games.gameComments(
      this.$route.params.game_id,
      this.getParams
    )
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          v.timeago = timeago(v.time * 1000)
          return v
        })
      )
      this.prefetchNextPage()
    }
  }
  async prefetchNextPage() {
    const params = JSON.parse(JSON.stringify(this.getParams))
    params.page++
    const nextRes = await api.games.gameComments(
      this.$route.params.game_id,
      params
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        v.timeago = timeago(v.time * 1000)
        return v
      })
    }
  }

  handleHideOpts(e) {
    this.showStarOpts = false
    this.showSortOpts = false
  }

  @Watch("sort")
  onSort(c) {
    this.page = 1
    this.list = []
    this.nextList = []
    this.fetchData()
  }
  @Watch("star")
  onStar(c) {
    this.page = 1
    this.list = []
    this.nextList = []
    this.fetchData()
  }
}
