























import api from "@/api/api"
import { throttle } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

interface IRelatedGame {
  score: number
  game: string[]
}

@Component({
  name: "RelatedGame",
  components: {}
})
export default class RelatedGame extends Vue {
  @Prop({ type: Array, default: () => [] })
  related_game: IRelatedGame[]

  gameIds = []

  gameData = []

  async getGameList() {
    const ids = this.gameIds.splice(0, 8)
    const result = await Promise.allSettled(
      ids.map(v => {
        return api.games.detail(v)
      })
    )
    result.forEach(item => {
      if (item.status === "fulfilled") {
        this.gameData.push(item.value.data)
      }
    })
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.scrollHandle, true)
    })
  }
  scrollHandle = throttle(this.scrollThrottle, 200, this, true)
  scrollThrottle() {
    if (this.$refs.last) {
      const offset = (this.$refs.last as HTMLElement).getBoundingClientRect() // vue中，使用this.$el获取当前组件的根元素
      const offsetLeft = offset.left

      if (offsetLeft < document.body.clientWidth * 1.5) {
        this.getGameList()
      }
    }
  }
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandle, true)
  }

  @Watch("related_game", { immediate: true })
  onIds(related_game) {
    if (related_game) {
      this.gameIds = related_game
        .map(v => v.game_seq)
        .reduce((pre, cur) => {
          return pre.concat(cur)
        }, [])
      this.getGameList()
    }
  }
  handleDetail(id) {
    this.$store.commit("position/SAVE_GAME_DETAIL", 0)
  }
}
