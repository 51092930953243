









































import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "GameCard",
  components: {}
})
export default class GameCard extends Vue {
  $refs: { dplayerVideo: any; videoWrapper: HTMLElement; tags: HTMLElement }

  @Prop({ type: Object }) data

  image = ""
  poster = ""
  video = ""
  name = ""
  tags = []
  desc = ""
  score = ""
  display_content = "image"
  bgc = ""
  game_id = ""
  _seq = ""

  options = {
    screenshot: false,
    video: {
      _id: "",
      url: "",
      pic: "",
      type: "auto"
    }
  }

  @Watch("data", { immediate: true })
  onData(c) {
    if (c) {
      const { link_game, display_content, recommend_text } = c
      this.display_content = display_content
      this.game_id = link_game._id
      this._seq = link_game._seq
      this.name = link_game.name
      this.desc = recommend_text || link_game.brief
      this.score = link_game.new_star
      this.tags = link_game.new_tag_style
        ? link_game.new_tag_style.map(v => ({ name: v.name, _seq: v._seq }))
        : []
      if (display_content === "image") {
        this.image = link_game.home_setting?.image
        this.bgc = link_game.home_setting?.placeholder_color
      }
      if (display_content === "video") {
        this.options.video._id = link_game.top_video?.video_id
        this.options.video.pic = link_game.top_video?.poster
        this.options.video.url = link_game.top_video?.url
        this.image = link_game.home_setting?.image
        this.bgc = link_game.home_setting?.placeholder_color
      }
      if (!this.$isServer) {
        this.calcTagsWidth(218)
      }
    }
  }

  destroyed() {}

  handleDetail() {
    this.$store.commit("position/SAVE_GAME_DETAIL", 0)
  }

  calcTagsWidth(maxWidth: number) {
    this.$nextTick(() => {
      if (this.$refs.tags.offsetWidth > maxWidth) {
        let width = 0
        let flag = false
        Array.prototype.forEach.call(this.$refs.tags.children, function(tag) {
          if (flag) {
            tag.style.display = "none"
            return
          }
          width += tag.offsetWidth
          if (width > maxWidth) {
            flag = true
            tag.style.display = "none"
          }
        })
      }
    })
  }
}
