















import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "Contact",
  components: {}
})
export default class Contact extends Vue {
  backIcon = require("@/assets/images/biaoqian_icon_more.png")
  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }

  get content() {
    return this.$root.$tstore.modules.basic.getters.content
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.content) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  async fetchData() {
    return new Promise(async (resolve, reject) => {
      await this.$root.$tstore.modules.basic.actions.FETCH_INFO()
      const infos = this.$root.$tstore.modules.basic.getters.informations
      if (infos && infos.filter(v => v.title === "联系我们")[0]) {
        await this.$root.$tstore.modules.basic.actions.FETCH_CONTENT({
          _id: infos.filter(v => v.title === "联系我们")[0]._id
        })
      }
      resolve("")
    })
  }

  formatData() {}
}
