














































import { NTagSearch } from "@/api/api"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "SearchItem"
})
export default class SearchItem extends Vue {
  @Prop({ type: Object, default: () => {} }) item: NTagSearch.IItem

  mounted() {
    this.calcTagsWidth(170)
  }

  calcTagsWidth(maxWidth: number) {
    setTimeout(() => {
      if (this.$refs.tags) {
        Array.prototype.forEach.call(
          this.$refs.tags as HTMLDivElement,
          function(tags) {
            if (tags.offsetWidth > maxWidth) {
              let width = 0
              let flag = false
              if (tags.children) {
                Array.prototype.forEach.call(tags.children, function(tag) {
                  if (flag) {
                    tag.style.display = "none"
                    return
                  }
                  width += tag.offsetWidth
                  if (width > maxWidth) {
                    flag = true
                    tag.style.display = "none"
                  }
                })
              }
            }
          }
        )
      }
    }, 0)
  }

  handleDetail(game_id) {
    this.$router.replace(`/${game_id}`)
  }

  handleTag(name, e) {
    e.preventDefault()
    e.stopPropagation()
    if (name === this.$route.params.tag_name) return
    this.$router.push(`/tag/${name}`)
  }
}
