














































































































import { Component, Vue } from "vue-property-decorator"
import { jsApiShare } from "./extra"

@Component({
  name: "TestPage",
  components: {}
})
export default class TestPage extends Vue {
  mounted() {
    setTimeout(() => {
      var btn = document.getElementById("launch-btn")
      btn.addEventListener("launch", function(e) {
        console.log("success")
      })
      btn.addEventListener("error", function(e) {
        console.log("fail", e)
      })
      jsApiShare(
        "test",
        "testDEs",
        "https://static-web.ghzs.com/gh-website/logo.png",
        location.href
      )
    }, 0)
  }

  handleClick() {
    var ev = document.createEvent("Event")
    // @ts-ignore
    ev.initEvent("launch", true, true)
    var btn = document.getElementById("launch-btn")
    btn.dispatchEvent(ev)
  }
}
