




















/**
 * @description 浮动下载
 * 解释 -
 * 浮动下载为下滑滚动条后显示在页面底部的下载组件
 */
import { downloadLog } from "@/utils/log"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "FloatDownload"
})
export default class Empty extends Vue {
  handleClose() {
    this.$emit("close")
  }

  handleDownload() {
    this.$emit("download")
    downloadLog()
    window.open("https://download.ghzs.com/ghzs?channel=GH_112", "_blank")
    // window.open("https://m.ghzs.com/", "_blank")
  }
}
