























import { DateFotmat, urlScheme } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "Server",
  components: {}
})
export default class Server extends Vue {
  urlScheme = urlScheme
  @Prop({ type: Array, default: () => [] })
  calendar: any[]

  DateFotmat = DateFotmat
}
