














import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "SearchResult",
  beforeRouteUpdate(to, from, next) {
    if (to.query.type) {
      this.type = to.query.type as string
    }
    this.key[this.type]++
    next()
  },
  components: {
    BbsSearch: () => import("../BbsSearch/BbsSearch.vue"),
    GameSearch: () => import("../GameSearch/GameSearch.vue")
  }
})
export default class SearchResult extends Vue {
  key = {
    game: 0,
    bbs: 0
  }
  type = "game"

  serverPrefetch() {
    this.getTypeFromRoute()
  }

  mounted() {
    this.getTypeFromRoute()
  }

  getTypeFromRoute() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type as string
    }
  }

  changeType(type) {
    this.type = type
    this.$router.replace({ path: this.$route.path, query: { type } })
  }
}
