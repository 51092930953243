


















import { MBasic } from "@/api/modules/basic"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "MapItem",
  components: {}
})
export default class MapItem extends Vue {
  @Prop({})
  propData: MBasic.IMapItem

  handleLink(url) {
    window.open(url, "_blank")
  }
}
