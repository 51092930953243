import api, { IRecommendContentItem } from "@/api/api"
import { ITableData, TableData } from "@/utils/base"

interface IState {
  list: any
  bbs: any
  contentSearchList: any
  gameSearchList: any
  bbsName: string
  officialBbsList: IOfficialBbsItem[]
  recommendContents: ITableData<IRecommendContentItem>
  hotBbsList: IHotBbsItem[]
  sections: ISectionItem[]
}

export const bbs = {
  namespaced: true,
  state: () => ({
    list: null, // 首页论坛选择列表
    bbs: null, // 某游戏论坛
    contentSearchList: null, // 内容搜索列表
    gameSearchList: null, // 游戏搜索列表
    bbsName: null, // 用作seo时记录是否有点击过论坛
    officialBbsList: null, // 官方论坛列表
    recommendContents: new TableData({}), // 首页帖子列表
    hotBbsList: null, // 热门论坛列表
    sections: null
  }),
  getters: {
    officialBbsList: state => state.officialBbsList,
    recommendContents: (state: IState) => state.recommendContents,
    hotBbsList: (state: IState) => state.hotBbsList,
    sections: (state: IState) => state.sections
  },
  mutations: {
    SET_LIST: (state, { list }) => {
      state.list = list
    },
    SET_BBS: (state, { bbs }) => {
      state.bbs = bbs
    },
    SET_GAME_SEARCH: (state, { list }) => {
      state.gameSearchList = list
    },
    SET_CONTENT_SEARCH: (state, { list }) => {
      state.contentSearchList = list
    },
    SET_BBS_NAME: (state, { bbsName }) => {
      state.bbsName = bbsName
    },
    SET_OFFICIAL_BBS_LIST: (state, { officialBbsList }) => {
      state.officialBbsList = officialBbsList
    },
    SET_RECOMMEND_CONTENTS: (state, data: IState["recommendContents"]) => {
      state.recommendContents = Object.assign(state.recommendContents, data)
    },
    SET_HOT_BBS_LIST: (state, { list }) => {
      state.hotBbsList = list
    },
    SET_SECTIONS: (state, { list }) => {
      state.sections = list
    }
  },
  actions: {
    FETCH_LIST: ({ commit, state }) => {
      return api.bbs.list().then(res => commit("SET_LIST", { list: res.data }))
    },
    FETCH_BBS: ({ commit }, { id }) => {
      return api.bbs.detail(id).then(res => {
        commit("SET_BBS", { bbs: res.data })
      })
    },
    FETCH_HOT_BBS_LIST: ({ commit }) => {
      return api.bbs
        .hotBbses()
        .then(res => commit("SET_HOT_BBS_LIST", { list: res.data }))
    },
    FETCH_GAME_SEARCH: ({ commit }, { keyword, bbs_id, type, page }) => {
      return api.content
        .searchName({
          keyword,
          bbs_id,
          type,
          page
        })
        .then(res => commit("SET_GAME_SEARCH", { list: res.data }))
    },
    FETCH_CONTENT_SEARCH: (
      { commit },
      { keyword, bbs_id = null, type = null, page = null }
    ) => {
      return api.content
        .search({
          keyword,
          bbs_id,
          type,
          page
        })
        .then(res => {
          if (type === "game") {
            commit("SET_GAME_SEARCH", { list: res.data })
          } else {
            commit("SET_CONTENT_SEARCH", { list: res.data })
          }
        })
    },
    FETCH_BBS_NAME: ({ commit }, { id }) => {
      return api.bbs.detail(id).then(res => {
        commit("SET_BBS_NAME", { bbsName: res.data.name })
      })
    },
    FETCH_OFFICIAL_BBS: ({ commit }) => {
      return api.bbs.officialList().then(res =>
        commit("SET_OFFICIAL_BBS_LIST", {
          officialBbsList: res.data
        })
      )
    },
    FETCH_RECOMMEND_CONTENTS: ({ commit }) => {
      return api.bbs
        .recommendContents({ page: 1, page_size: 30, sort: "time.comment:-1" })
        .then(res => {
          commit("SET_RECOMMEND_CONTENTS", {
            data: res.data,
            page: 1,
            page_size: 30,
            finished: res.data.length < 30,
            total: res.headers.total || 0
          })
        })
    },
    FETCH_SECTIONS: ({ commit }, { bbs_id }) => {
      return api.bbs
        .sectionsList(bbs_id)
        .then(res => commit("SET_SECTIONS", { list: res.data }))
    }
  }
}

interface IOfficialBbsItem {
  _id: string
  name: string
  icon: string
  me: IMe
}

interface IMe {
  is_follow_bbs: boolean
}

interface IHotBbsItem {
  _seq: number
  _id: string
  name: string
  category_id: string[]
  type: string
  hot: number
  game: {
    _id: string
    icon: string
    ori_icon: string
  }
  icon?: string
}

interface ISectionItem {
  icon: string
  icon_type: string
  name: string
  permissions: string
  _id: string
}
