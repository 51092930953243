


















































import { IJuheGameItem } from "@/api/modules/basic"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { DateFotmat } from "@/utils/base"

@Component({
  name: "GameItem",
  components: {
    Item: () => import("../Item/Item.vue")
  }
})
export default class GameItem extends Vue {
  @Prop({})
  propData: IJuheGameItem

  DateFotmat = DateFotmat
}
