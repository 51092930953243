// @ts-nocheck

import axios from "axios"
export function loadScript(url, callback) {
  var script = document.createElement("script")
  script.type = "text/javascript"
  script.src = url

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = function() {
      callback()
    }
  }
  document.body.appendChild(script)
}
export function jsApiShare(title, desc, img, url) {
  loadScript("https://res.wx.qq.com/open/js/jweixin-1.6.0.js", function() {
    loaded()
  })

  const formatURL = encodeURIComponent(url)
  const loaded = function() {
    axios
      .post("https://jsapi.ghzs.com/api/wxshare?tmp=" + Math.random(), {
        url: formatURL
      })
      .then(res => {
        const config = res.data
        if (typeof config === "object") {
          if (
            !config.jsApiList ||
            (Array.isArray(config.jsApiList) && config.jsApiList.length === 0)
          ) {
            config.jsApiList = [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "onMenuShareWeibo",
              "launchApplication"
            ]
          }
          config.debug = true
          // 7.0.12 以上支持唤起微信客户端和小程序
          config.openTagList = ["wx-open-launch-app"]

          var $dom = document.querySelector("#showconfig")
          $dom.innerHTML = "获取微信配置成功"
          console.log(config)

          window.wx.config(config)

          window.wx.ready(function() {
            const shareData = {
              link: decodeURIComponent(formatURL),
              imgUrl: img,
              title: title,
              desc: desc,
              success: function(res) {},
              fail: function(res) {}
            }
            // 分享给朋友
            window.wx.updateAppMessageShareData(shareData)
            // 朋友圈
            window.wx.updateTimelineShareData(shareData)
          })
        }
      })
  }
}
