



































import { Component, Vue, Watch } from "vue-property-decorator"
import api from "@/api/api"
import updateTdk, { formatTDK } from "@/utils/tdk"

@Component({
  name: "JXWD",
  components: {
    ChoiceItem: () => import("./components/ChoiceItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class JXWD extends Vue {
  backIcon = require("@/assets/images/biaoqian_icon_more.png")
  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }

  loading = false

  get seoChoice() {
    return this.$root.$tstore.modules.basic.getters.seoChoice
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (this.seoChoice.data.length === 0) {
      this.loading = true
      this.fetchData()
    }
  }

  async fetchData() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.basic.actions.FETCH_SEO_CHOICE()
        await this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
          type: "manual",
          _id_or_name: encodeURI("seo精选内容")
        })

        updateTdk.call(this)
        resolve("")
      } catch (error) {
        reject(error)
      } finally {
        this.loading = false
      }
    })
  }

  async getList() {
    const res = await api.basic.seoChoice({
      page: this.seoChoice.page,
      page_size: this.seoChoice.page_size
    })
    // this.loading = false
    if (res.data.length === 0) {
      this.$root.$tstore.modules.basic.mutations.SET_SEO_CHOICE({
        finished: true
      })
    } else {
      this.$root.$tstore.modules.basic.mutations.SET_SEO_CHOICE({
        data: this.seoChoice.data.concat(res.data)
      })
    }
  }
  handleLoadMore() {
    this.$root.$tstore.modules.basic.mutations.SET_SEO_CHOICE({
      page: this.seoChoice.page + 1
    })
    this.getList()
  }
}
