import { render, staticRenderFns } from "./Abstract.vue?vue&type=template&id=989583ba&scoped=true"
import script from "./Abstract.vue?vue&type=script&lang=ts"
export * from "./Abstract.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Abstract.vue?vue&type=style&index=0&id=989583ba&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "989583ba",
  "60bcedca"
  
)

export default component.exports