
















































































































import { formatURLInHTML, urlScheme } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "CommentCard",
  components: {}
})
export default class CommentCard extends Vue {
  urlScheme = urlScheme

  $refs: { content: HTMLElement }

  @Prop()
  item

  @Prop()
  justContent: boolean

  // 内容是否是a标签
  @Prop({
    default: true,
    required: false
  })
  canJump: boolean

  @Prop()
  fold: boolean

  isFold = false

  expand = {
    down: require("@/assets/images/icon_down.png"),
    up: require("@/assets/images/icon_up.png")
  }

  starIcon = {
    icon: require("@/assets/images/anli_Star_gray.png"),
    activeIcon: require("@/assets/images/anli_Star_blue.png")
  }

  get getContent() {
    const maxLen = Math.floor(this.$refs.content?.offsetWidth / 12) * 4 - 4

    const content = this.isFold
      ? this.formatContent(this.item.content.slice(0, maxLen) + "...")
      : this.formatContent(this.item.content)

    return formatURLInHTML(content)
  }

  @Watch("fold", { immediate: true })
  onFold(fold) {
    if (fold) {
      this.$nextTick(() => {
        if (this.$refs.content?.offsetHeight > 18 * 4) {
          this.isFold = true
        }
      })
    }
  }

  formatContent(str) {
    const re = /(<tag>.*?<\/tag>)/g
    if (re.test(str)) {
      const reTag = /(&lttag&rt.*?&lt\/tag&rt)/g
      str = str.replace(/</gi, "&lt").replace(/>/gi, "&rt")
      str = str
        .replace(/\n/g, "<br />")
        .replace(/\r\n/g, "<br />")
        .replace(/\r/g, "<br />")
        .replace(/↵/g, "<br />")
        .replace(/&lttag&rt/gi, "<tag>")
        .replace(/&lt\/tag&rt/gi, "</tag>")

      str = str.replace(re, item => {
        const newStr =
          '<span class="text-blue" style="color:#1383eb">' +
          "#" +
          item.replace(/<[^>]*?>(.*?)/gi, "").replace(/(.*?)<\/[^>]*?>/gi, "") +
          "#&nbsp;" +
          "</span>"
        return newStr
      })
      return str
    } else {
      str = str.replace(/<[^>]*?>(.*?)/gi, "").replace(/(.*?)<\/[^>]*?>/gi, "")
      return str
    }
  }
}
