













import { Component, Prop, Vue } from "vue-property-decorator"
import animationData from "@/assets/lottie/loading.json"
@Component({
  name: "LoadMore",
  components: { lottie: () => import("@/components/lottie/lottie.vue") }
})
export default class LoadMore extends Vue {
  defaultOptions = { animationData: animationData }

  @Prop({ type: String, default: "加载更多内容" })
  text

  @Prop({ type: Number, default: 18 })
  iconWidth

  @Prop({ type: Number, default: 18 })
  iconHeight
}
