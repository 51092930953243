import api from "@/api/api"
interface IQuesState {
  detail: null
  commentList: null
  count: null
}

export const ques = {
  namespaced: true,
  state: () => ({
    detail: null,
    commentList: null,
    count: null
  }),
  getters: {
    detail: state => state.detail,
    commentList: state => state.commentList,
    count: state => state.count
  },
  mutations: {
    SET_DETAIL: (state: IQuesState, { detail }) => {
      state.detail = detail
    },
    SET_COMMENT_LIST: (state: IQuesState, { commentList }) => {
      state.commentList = commentList
    },
    SET_COUNT: (state: IQuesState, { count }) => {
      state.count = count
    }
  },
  actions: {
    FETCH_DETAIL: ({ commit }, { ques_id }) => {
      return api.question
        .detail(ques_id)
        .then(res => commit("SET_DETAIL", { detail: res.data }))
    },
    FETCH_COMMENT_LIST: ({ commit }, { ques_id, params }) => {
      return api.question.comments(ques_id, params).then(res =>
        commit("SET_COMMENT_LIST", {
          commentList: res.data
        })
      )
    },
    FETCH_COUNT: ({ commit }, { article_id }) => {
      return api.article
        .count(article_id)
        .then(res => commit("SET_COUNT", { count: res.data[0]?.num || "" }))
    }
  }
}
