import Vue from "vue"
import App from "./App.vue"
import createRouter from "./router"
import createStore from "./store"
import "./styles/reset.css"
import "./plugins"
import Api from "./api/api"
import globalMixin from "@/plugins/mixin"
import Tools from "@/utils/tools"

Vue.prototype.$api = Api
Vue.mixin({
  ...globalMixin
})
Tools(Vue)

Vue.config.productionTip = false
Vue.config.ignoredElements = ["wx-open-launch-app"]

export function createApp() {
  const router = createRouter()
  const $tstore = createStore()
  const app = new Vue({
    router,
    store: $tstore.store,
    render: h => h(App)
  })
  app.$tstore = $tstore
  return { app, router, $tstore }
}
