






































import { Datum } from "@/api/modules/toolkit"
import { TLocalStorageTypes } from "@/config"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Search",
  beforeRouteLeave(to, from, next) {
    if (to.name === "toolkitSearchResult") {
      this.fetchData(to.params.toolkitKeyword)
    }
    next()
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to.params.toolkitKeyword)
    next()
  },
  components: { Empty: () => import("@/components/empty/empty.vue") }
})
export default class Search extends Vue {
  keyword = ""

  get originList() {
    return this.$root.$tstore.modules.toolkit.getters.searchList
  }

  list: Datum[] = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData(this.$route.params.toolkitKeyword)
  }

  beforeMount() {
    if (!this.originList) {
      this.fetchData(this.$route.params.toolkitKeyword)
    } else {
      this.formatData()
    }
  }
  beforeDestroy() {
    this.$root.$tstore.modules.toolkit.mutations.SET_SEARCH_LIST({
      searchList: null
    })
  }

  fetchData(keyword) {
    if (!keyword) return
    return this.$root.$tstore.modules.toolkit.actions
      .FETCH_SEARCH_LIST({
        keyword
      })
      .then(this.formatData)
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        // updateTdk.call(this)
      })
  }
  formatData() {
    this.list = JSON.parse(JSON.stringify(this.originList))
    this.keyword = this.$route.params.toolkitKeyword
  }
  handleEnter() {
    const path = "/toolkit/search/" + this.keyword.trim()
    if (
      this.keyword.length > 0 &&
      this.keyword.trim() !== this.$route.params.keyword
    ) {
      if (this.$route.fullPath !== path) {
        this.$router.replace(path)
        ;(this.$refs.seachInput as any).blur()
      }
    }
  }

  open(data: Datum) {
    this.saveClickCache(data)

    window.open(data.url, "_blank")
  }

  saveClickCache(data: Datum) {
    if (localStorage.getItem(TLocalStorageTypes.CLICKEDTOOLS)) {
      const list = JSON.parse(
        localStorage.getItem(TLocalStorageTypes.CLICKEDTOOLS)
      )
      if (!list.map(v => v._id).includes(data._id)) {
        if (list.length >= 4) {
          list.shift()
        }
        list.push(data)
      }
      localStorage.setItem(
        TLocalStorageTypes.CLICKEDTOOLS,
        JSON.stringify(list)
      )
    } else {
      localStorage.setItem(
        TLocalStorageTypes.CLICKEDTOOLS,
        JSON.stringify([data])
      )
    }
  }

  handleBack() {
    this.$router.replace("/toolkit")
  }
}
