












































import { urlScheme } from "@/utils/base"
import { DateFotmat, timeago } from "@/utils/base"
import { Component, Vue, Prop } from "vue-property-decorator"
import updateTdk, { formatTDK } from "@/utils/tdk"

@Component({
  name: "GameComment",
  components: {
    CommentCard: () =>
      import(
        "@/views/Game/components/Comments/components/CommentCard/CommentCard.vue"
      ),
    Footer: () => import("@/components/footer/footer.vue"),
    ReplyList: () => import("./components/ReplyList/ReplyList.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class GameComment extends Vue {
  urlScheme = urlScheme
  gameDetail = {
    name: "",
    icon: "",
    version: "",
    size: "",
    download_status: "",
    category: ""
  }
  commentDetail = null
  replyNum = 0

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (
      !this.$store.state.gameList.gameCommentDetail ||
      !this.$store.state.gameList.gameAbstract ||
      !this.$store.state.gameList.gameDetail
    ) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }
  destroyed() {
    this.$store.commit("gameList/SET_GAME_ABSTRACT", {
      gameAbstract: null
    })
    this.$store.commit("gameList/SET_GAME_DETAIL", {
      gameDetail: null
    })
    this.$store.commit("gameList/SET_GAME_COMMENT_DETAIL", {
      gameCommentDetail: null
    })
  }

  fetchData() {
    return Promise.all([
      this.$store.dispatch("tdk/FETCH_SUB_TDK", {
        type: "game",
        _id_or_name: this.$route.params.game_id,
        sub_type: "comment_detail"
      }),
      this.$store.dispatch("gameList/FETCH_GAME_ABSTRACT", {
        game_id: this.$route.params.game_id
      }),
      this.$store.dispatch("gameList/FETCH_GAME_DETAIL", {
        game_id: this.$route.params.game_id
      }),
      this.$store.dispatch("gameList/FETCH_GAME_COMMENT_DETAIL", {
        game_id: this.$route.params.game_id,
        comment_id: this.$route.params.comment_id
      })
    ])
      .then(() => {
        this.formatData()

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.$store.state.gameList.gameAbstract.name
            },
            {
              replaceKey: "{评论内容}",
              replaceVal: this.commentDetail.content.substr(0, 50)
            },
            {
              replaceKey: "{用户ID}",
              replaceVal: this.commentDetail.user.name
            }
          ])
        )
      })
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        updateTdk.call(this)
      })
  }

  formatData() {
    const info = this.$store.state.gameList.gameDetail.detail_tab.filter(
      v => v.type === "info"
    )[0]?.info
    this.gameDetail.version = info.version
    this.gameDetail.size = info.size
    this.gameDetail.download_status = info.download_status

    this.gameDetail.category = this.$store.state.gameList.gameAbstract.category
    this.gameDetail.name = this.$store.state.gameList.gameAbstract.name
    this.gameDetail.icon = this.$store.state.gameList.gameAbstract.icon

    this.commentDetail = this.$store.state.gameList.gameCommentDetail
    this.commentDetail.timeago = timeago(this.commentDetail.time * 1000)
    this.commentDetail.content = this.commentDetail.content
    this.replyNum = this.commentDetail.reply
  }

  handleBack() {
    this.$router.push(`/${this.$route.params.game_id}`)
  }
}
