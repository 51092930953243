
































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { ImagePreview } from "vant"
import { redirectAppLinkInHTML, urlScheme } from "@/utils/base"

interface ICustomColumn {
  _id: string
  name: string
  link: Link
  order: number
  title?: any
  des: string
  show_info_tag: boolean
  show_info_tag_des: boolean
  info_tag: Infotag[]
  time: number
  name_icon: string
  name_link: Namelink
  show_des_row_num: number
  show_des_type: string
  show_name: boolean
  tag_des: string
  show_info_tag_des_type: string
}
interface Namelink {
  type: string
  value: string
  text: string
  image: string
  link: string
}
interface Infotag {
  name: string
  icon: string
  color: string
  des: string
}
interface Link {
  type: string
  value: string
  text: string
  icon_id: string
  icon: string
  link: string
}

@Component({
  name: "CustomColumn",
  components: {
    EllipsisText: () => import("@/components/EllipsisText/EllipsisText.vue")
  }
})
export default class CustomColumn extends Vue {
  redirectAppLinkInHTML = redirectAppLinkInHTML
  urlScheme = urlScheme

  $refs: { content: HTMLElement }

  @Prop({ type: Object, default: () => {} })
  dataProp: ICustomColumn

  data = {
    nameIcon: "",
    name: "",
    linkText: "",
    rightText: "",
    des: "",
    tags: [],
    show_des_row_num: 0,
    tag_des: ""
  }

  isFold = false
  isHide = false

  showExpands = false

  mounted() {}

  @Watch("dataProp", { immediate: true })
  onData(data: ICustomColumn) {
    if (data) {
      if (
        (data.info_tag && data.info_tag.length > 0) ||
        (!data.name_icon && !data.des)
      ) {
        this.isHide = true

        return
      }

      this.data.nameIcon = data.name_icon
      this.data.name = data.name
      this.data.linkText = data.name_link?.value || ""
      this.data.rightText = data.link?.value || ""
      this.data.des = data.des
      this.data.tag_des = data.tag_des

      this.data.show_des_row_num = data.show_des_row_num

      this.data.tags = data.info_tag
    }
  }

  formatContent(str) {
    const re = /(<p>.*?<\/p>)/g
    if (re.test(str)) {
      str = str
        .replace(/\n/g, "<br />")
        .replace(/\r\n/g, "<br />")
        .replace(/\r/g, "<br />")
        .replace(/↵/g, "<br />")
      return str
    } else {
      str = str.replace(/<[^>]*?>(.*?)/gi, "").replace(/(.*?)<\/[^>]*?>/gi, "")
      return str
    }
  }

  handleHide() {
    this.isFold = false
    this.data.des = this.formatContent(this.dataProp.des)
  }
}
