

































import { Component, Vue, Prop, Watch } from "vue-property-decorator"

export interface videoDataProps {
  poster: string
  url: string
  video_id: string
}

@Component({
  name: "TopVideo",
  components: {
    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue")
  }
})
export default class TopVideo extends Vue {
  @Prop()
  videoDataProps: videoDataProps

  videoData = null
  isFullsreenVideo = false
  showPoster = true
  showDPlayer = false
  bottomzIndex = 1

  onFullsreen(isFull) {
    this.isFullsreenVideo = isFull
    if (isFull) {
      this.bottomzIndex = -1
    } else {
      this.bottomzIndex = 1
    }
  }

  mounted() {
    this.showDPlayer = true
  }

  play() {
    this.showPoster = false
    setTimeout(() => {
      const player = (this.$refs.dplayerVideo as any).load()
      player.play()
    }, 100)
  }

  @Watch("videoDataProps", { immediate: true })
  onData(data: videoDataProps) {
    if (data) {
      this.videoData = {
        type: "video",
        url: data.url,
        cover: data.poster,
        videoOptions: {
          screenshot: false,
          video: {
            _id: data.video_id,
            url: data.url || "",
            pic: data.poster,
            type: "auto"
          }
        }
      }
    }
  }
}
