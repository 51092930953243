







































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { urlScheme } from "@/utils/base"
@Component({
  name: "Rating",
  components: {}
})
export default class Rating extends Vue {
  urlScheme = urlScheme

  star = {
    icon: require("@/assets/images/anli_Star_gray.png"),
    activeIcon: require("@/assets/images/anli_Star_blue.png")
  }
  average = ""
  percentages = []
  loading = false

  calcPercentage(score, hits) {
    if (hits <= 3) {
      return 0
    }

    let percentage = (score / hits) * 100
    if (0 < percentage && percentage < 1) {
      percentage = 1
    }
    return percentage.toFixed(0)
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (!this.$store.state.gameList.gameRating) {
      this.fetchData().then(this.fetchDataMounted)
    } else {
      this.fetchDataMounted()
    }
  }
  destroyed() {
    this.$store.commit("gameList/SET_GAME_RATING", { gameRating: null })
  }

  fetchData() {
    return this.$store
      .dispatch("gameList/FETCH_GAME_RATING", {
        game_id: this.$route.params.game_id
      })
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
  }

  fetchDataMounted() {
    const {
      average,
      one,
      two,
      three,
      four,
      five,
      hits
    } = this.$store.state.gameList.gameRating
    this.average = average
    this.percentages[0] = this.calcPercentage(five / 10, hits)
    this.percentages[1] = this.calcPercentage(four / 8, hits)
    this.percentages[2] = this.calcPercentage(three / 6, hits)
    this.percentages[3] = this.calcPercentage(two / 4, hits)
    this.percentages[4] = this.calcPercentage(one / 2, hits)
  }
}
