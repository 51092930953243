
































/**
 * @description 搜索页面头部组件
 */
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "SearchHeader"
})
export default class SearchHeader extends Vue {
  @Prop({ type: String, default: "" }) value!: string // 关键词
  @Prop({ type: String, default: "" }) placeholder!: string // placeholder
  @Prop({ type: Boolean, default: false }) blur!: boolean // 失去焦点
  @Prop({ type: Boolean, default: false }) focus!: boolean // 获取焦点

  get currentValue() {
    return this.value
  }

  set currentValue(newVal: string) {
    this.$emit("input", newVal)
  }

  setBlur() {
    const dom: any = this.$refs._searchInput
    dom.blur()
  }

  getActive() {
    const dom: any = this.$refs._searchInput
    return dom === document.activeElement // 输入框正在聚焦
  }

  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }
}
