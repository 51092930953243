


















import { ILink } from "@/api/modules/novel"
import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "NovelHot",
  components: {}
})
export default class NovelHot extends Vue {
  get hotList() {
    return this.$root.$tstore.modules.novel.getters.hotList
  }

  // ssr hook
  serverPrefetch() {
    return this.$root.$tstore.modules.novel.actions.FETCH_HOT_LIST()
  }

  async beforeMount() {
    if (!this.hotList) {
      return this.$root.$tstore.modules.novel.actions.FETCH_HOT_LIST()
    }
  }

  formatUrl(link: ILink) {
    let url = ""
    switch (link.type) {
      case "column":
        url = `/column/${link.link_seq}`
        break
      case "community":
        url = `/bbs/${link.link_seq}`
        break
      case "tag":
        url = `/tag/${link.link_seq}`
        break
      case "game":
        url = `/${link.link_seq}`
        break
    }
    return url
  }
}
