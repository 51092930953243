










































































































import { NVideo } from "@/api/modules/video"
import { timeago } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import downloadDialog from "@/components/downloadDialog"
import shareDialog from "@/components/shareDialog"

@Component({
  name: "Info",
  components: {}
})
export default class Info extends Vue {
  @Prop({ type: Object, default: {} }) data: NVideo.IDetail

  timeago = timeago

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }
  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  $refs: { content: HTMLElement }

  isFold = false
  get getContent() {
    const maxLen = Math.floor(this.$refs.content?.offsetWidth / 13) * 3 - 4
    return this.isFold ? this.data.des.slice(0, maxLen) + "..." : this.data.des
  }

  @Watch("data", { immediate: true })
  onText(data) {
    if (data) {
      this.$nextTick(() => {
        if (this.$refs.content?.offsetHeight > 20 * 3) {
          this.isFold = true
        }
      })
    }
  }
}
