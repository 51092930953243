import { render, staticRenderFns } from "./OfficialBbsDetail.vue?vue&type=template&id=5d3b17bd&scoped=true"
import script from "./OfficialBbsDetail.vue?vue&type=script&lang=ts"
export * from "./OfficialBbsDetail.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./OfficialBbsDetail.vue?vue&type=style&index=0&id=5d3b17bd&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5d3b17bd",
  "01267cf8"
  
)

export default component.exports