












































































import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog from "@/components/downloadDialog"

@Component({
  name: "OfficialBbsDetail",
  components: {
    Img: () => import("@/components/image/image.vue")
  }
})
export default class OfficialBbsDetail extends Vue {
  @Prop()
  bbs

  isExpand: boolean = false

  handleTop(item) {
    if (item.type === "community_article") {
      this.$router.push(`/bbs/${item.link_community._id}/article/${item.link}`)
    } else if (item.type === "article") {
      this.$router.push(`/article/${item.link}`)
    }
  }

  handleFollow() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          bbs_id: this.$route.params.bbs_id
        }
      },
      () => {}
    )
  }
}
