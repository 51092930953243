


















import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "Top",
  components: {
    Des: () => import("./Des/Des.vue"),
    CustomColumn: () => import("../CustomColumn/CustomColumn.vue")
  }
})
export default class Top extends Vue {
  @Prop({ type: Array, default: () => [] })
  top: any[]

  isShowDivider(idx, item) {
    let flag = true
    if (idx === this.top.length - 1) {
      flag = false
    }
    if (
      item.type === "custom_column" &&
      ((item.custom_column.info_tag &&
        item.custom_column.info_tag.length > 0) ||
        (!item.custom_column.name_icon && !item.custom_column.des))
    ) {
      flag = false
    }
    return flag
  }
}
