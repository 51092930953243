

































































































import { Component, Vue, Watch } from "vue-property-decorator"
import downloadDialog from "@/components/downloadDialog"
import api from "@/api/api"
import { getUserToken, inPlugin, checkPluginLogin } from "@/utils/base"
import { useContentApi } from "@/api/useContentApi"
import commentDialog from "@/components/commentDialog"

@Component({
  name: "Comment",
  components: {
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  }
})
export default class Comment extends Vue {
  get detail() {
    return this.$store.state.article.detail
  }

  get count() {
    return this.$store.state.article.count
  }

  get commentList() {
    return this.$store.state.article.commentList
  }

  page = 1
  pageSize = 20
  loading = false
  finished = false
  list = []
  nextList = []

  async handleLoadMore() {
    this.page++

    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }

    const res = await api.article.comments(this.$route.params.article_id, {
      page: this.page,
      page_size: this.pageSize
    })
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(res.data)
      this.prefetchNextPage()
    }
  }
  async prefetchNextPage() {
    const nextRes = await api.article.comments(this.$route.params.article_id, {
      page: this.page + 1,
      page_size: this.pageSize
    })
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data
    }
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (this.isInPlugin && this.isPluginLogin) {
      this.clientGetData()
    } else {
      if (this.commentList) {
        this.list = this.commentList
      } else {
        this.clientGetData()
      }
    }
  }

  clientGetData() {
    this.loading = true
    this.fetchData()
      .then(res => {
        this.list = this.commentList
      })
      .finally(() => {
        this.loading = false
      })
  }

  fetchData() {
    return Promise.all([
      this.$store.dispatch("article/FETCH_COMMENT_LIST", {
        article_id: this.$route.params.article_id,
        params: {
          page: this.page,
          pageSize: this.pageSize
        }
      }),
      this.$store.dispatch("article/FETCH_COUNT", {
        article_id: this.$route.params.article_id
      })
    ])
  }

  destroyed() {
    this.$store.commit("article/SET_COMMENT_LIST", { commentList: null })
  }

  handleDownload() {
    if (this.isInPlugin) {
      return
    }
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  @Watch("commentList")
  onCommentList(c) {
    if (c && c.length === 0) {
      this.finished = true
    }
  }

  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()
  async handleVote(item) {
    console.log(item)
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const isVote = item?.me?.is_comment_voted || false

      if (isVote) {
      } else {
        const api = useContentApi(getUserToken())
        try {
          await api.vote_comment.article(item._id)

          this.$set(item, "me", {
            ...item.me,
            is_comment_voted: true
          })
          if (item.vote) {
            item.vote++
          } else {
            item.vote = 1
          }
        } catch (error) {
          console.error(error)
        }
      }
      return
    }

    this.$downloadDialog()
  }

  handleComment(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const api = useContentApi(getUserToken())
      commentDialog(
        {
          rmAfterDestroy: true,
          presetData: {}
        },
        async ({ content }) => {
          try {
            await api.reply_comment.communityArticle(item._id, { content })
            this.refresh()
          } catch (error) {
            console.log(error)
            this.$toast("评论失败")
          }
        }
      )
      return
    }
    this.$downloadDialog()
  }

  refresh() {
    this.page = 1
    this.list = []
    this.nextList = []
    this.fetchData()
      .then(res => {
        this.list = this.commentList
        this.prefetchNextPage()
      })
      .finally(() => {
        this.loading = false
      })
  }
}
