


























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import RelatedGame from "./components/RelatedGame/RelatedGame.vue" // 异步加载会导致patchNode阶段报错，暂时改为同步
@Component({
  name: "Detail",
  components: {
    Top: () => import("./components/Top/Top.vue"),
    Des: () => import("./components/Top/Des/Des.vue"),
    CustomColumn: () => import("./components/CustomColumn/CustomColumn.vue"),
    Video: () => import("./components/Video/Video.vue"),
    Gallery: () => import("./components/Gallery/Gallery.vue"),
    CommentArea: () => import("./components/Comment/Comment.vue"),
    UpdateText: () => import("./components/UpdateText/UpdateText.vue"),
    Info: () => import("./components/Info/Info.vue"),
    Article: () => import("./components/Article/Article.vue"),
    Server: () => import("./components/Server/Server.vue"),
    Libao: () => import("./components/Libao/Libao.vue"),
    RelatedVersion: () =>
      import("./components/RelatedVersion/RelatedVersion.vue"),
    ImageRecommend: () =>
      import("./components/ImageRecommend/ImageRecommend.vue"),
    RelatedGame
  }
})
export default class Detail extends Vue {
  @Prop()
  dataProps: any[]

  datas = []

  @Watch("dataProps", { immediate: true })
  onData(data) {
    if (data) {
      let _datas = JSON.parse(JSON.stringify(data))

      _datas = _datas.filter(item => {
        if (
          item.type &&
          item.type === "custom_column" &&
          item.custom_column.info_tag.length > 0
        ) {
          return false
        } else {
          return true
        }
      })

      const desIdx = _datas.map(v => v.type).indexOf("des")
      let topNum = 0
      for (let i = 0; i <= desIdx; i++) {
        if (_datas[i].type === "custom_column" || _datas[i].type === "des") {
          topNum++
        } else {
          break
        }
      }
      const top = {
        type: "top",
        top: _datas.splice(0, topNum)
      }
      if (top.top.length > 0) {
        _datas.unshift(top)
      }

      const infoIdx = _datas.map(v => v.type).indexOf("info")
      const infoCard = {
        type: "infoCard",
        infoCard: []
      }
      let infoNum = 1
      if (_datas[infoIdx + 1]) {
        for (let i = infoIdx + 1; i <= _datas.length; i++) {
          if (_datas[i].type !== "custom_column") {
            break
          } else {
            infoNum++
          }
        }
      }

      infoCard.infoCard = _datas.splice(infoIdx, infoNum)
      _datas.splice(infoIdx, 0, infoCard)

      this.datas = _datas
    }
  }
}
