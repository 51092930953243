import { apiApp } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"

export default function(service: AxiosInstance) {
  return {
    ranking: {
      list(params?: IList): Promise<AxiosResponse<IRankingItem[]>> {
        return service.get(`${apiApp}/column_ranking_list`, { params })
      }
    }
  }
}

interface IList {
  page?: number
  page_size?: number
  view?: string
  filter?: string
  keyword?: string
  active?: boolean
}

export interface IRankingItem {
  _id: string
  column_id: string
  title: string
}
