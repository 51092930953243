
























































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
@Component({
  name: "GameSearchList",
  components: {
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue")
  },
  tdk() {
    return this.metaInfo
  }
})
export default class GameSearchList extends Vue {
  get metaInfo() {
    return {
      title: `查看 ${this.$route.params.keyword}的搜索结果 | 要好玩，上光环！`,
      description: `光环助手为您找到以下和 ${this.$route.params.keyword}相关的游戏和论坛内容，更多 ${this.$route.params.keyword}内容，尽在光环`,
      keywords: `${this.$route.params.keyword} `
    }
  }

  loading = false
  showNone = false
  list = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.$store.state.gameList.gameSearchList) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  mounted() {
    this.calcTagsWidth(170)
  }

  fetchData() {
    this.loading = true
    if (!this.$store.state.gameList.filterTags) {
      return Promise.all([
        this.$store.dispatch("gameList/FETCH_GAME_SEARCH_LIST", {
          keyword: this.$route.params.keyword
        }),
        this.$store.dispatch("gameList/FETCH_FILTER_TAGS")
      ])
        .then(this.formatData)
        .catch(() => {
          // this.$router.push({ name: "404" })
        })
        .finally(() => {
          this.loading = false
          updateTdk.call(this)
        })
    }
    return this.$store
      .dispatch("gameList/FETCH_GAME_SEARCH_LIST", {
        keyword: this.$route.params.keyword
      })
      .then(this.formatData)
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        this.loading = false
        updateTdk.call(this)
      })
  }

  formatData() {
    try {
      this.list = this.$store.state.gameList.gameSearchList.map(v => {
        if (v.new_tag_style) {
          v.new_tag_style = v.new_tag_style.filter(
            tag => !this.$store.state.gameList.filterTags.includes(tag.name)
          )
        }
        return v
      })
    } catch (error) {
      console.log(error)
    }
    if (this.list.length === 0) {
      this.showNone = true
    }
  }

  calcTagsWidth(maxWidth: number) {
    setTimeout(() => {
      if (this.$refs.tags) {
        Array.prototype.forEach.call(
          this.$refs.tags as HTMLDivElement,
          function(tags) {
            if (tags.offsetWidth > maxWidth) {
              let width = 0
              let flag = false
              if (tags.children) {
                Array.prototype.forEach.call(tags.children, function(tag) {
                  if (flag) {
                    tag.style.display = "none"
                    return
                  }
                  width += tag.offsetWidth
                  if (width > maxWidth) {
                    flag = true
                    tag.style.display = "none"
                  }
                })
              }
            }
          }
        )
      }
    }, 0)
  }

  destroyed() {
    this.$store.commit("gameList/SET_GAME_SEARCH_LIST", {
      gameSearchList: null
    })
  }

  handleTag(name: string) {
    this.$router.push(`/tag/${name}`)
  }
}
