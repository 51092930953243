import { render, staticRenderFns } from "./LoadingMore.vue?vue&type=template&id=7c0749e7&scoped=true"
import script from "./LoadingMore.vue?vue&type=script&lang=ts"
export * from "./LoadingMore.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./LoadingMore.vue?vue&type=style&index=0&id=7c0749e7&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7c0749e7",
  "77f6f046"
  
)

export default component.exports