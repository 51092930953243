<template>
  <div id="app">
    <!-- <router-view /> -->
    <!-- 如果当前打开页面的路由中 keepAlive: true （开启了缓存时） -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <!-- 如果当前打开页面的路由中 没有 或者为 keepAlive: false （关闭缓存时[默认就是false]） -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { isPC } from "@/utils/base"
import { isProdDeploy } from "./config"
import { Component, Vue, Watch } from "vue-property-decorator"
import { Log } from "@/utils/log"

@Component({
  name: "App",
  components: {}
})
export default class App extends Vue {
  pvLog = () => {}
  mounted() {
    this.judgePath()

    setTimeout(() => {
      // web埋点
      this.pvLog = Log()
    }, 3000)

    if (!this.$root.$tstore.modules.gameList.getters.filterTags) {
      this.$root.$tstore.modules.gameList.actions.FETCH_FILTER_TAGS()
    }
  }
  adminPreviewRoutes = [
    "article",
    "communityArticle",
    "communityArticleThread",
    "video",
    "question"
  ]
  /**
   * @description issue https://git.ghzs.com/pm/web-issues/-/issues/142
   *  !(this.$route.query.flag && this.$route.query.flag === "preview") ？
   */
  judgePath() {
    if (isPC() && isProdDeploy()) {
      if (
        this.adminPreviewRoutes.includes(this.$route.name) &&
        this.$route.query.from &&
        this.$route.query.from === "admin"
      ) {
        return
      }

      if (this.$route.name === "search") {
        window.location.href = "https://www.ghzs666.com"
      } else if (
        this.$route.name === "contentSearch" &&
        this.$route.params.bbs_id
      ) {
        window.location.href = `https://www.ghzs666.com/bbs/${this.$route.params.bbs_id}`
      } else {
        window.location.href = `https://www.ghzs666.com${this.$route.fullPath}`
      }
    }
  }

  serverPrefetch() {
    return this.$root.$tstore.modules.gameList.actions.FETCH_FILTER_TAGS()
  }

  beforeDestroy() {
    if (this.pvLog && this.pvLog["clearEvent"]) this.pvLog["clearEvent"]()
  }
}
</script>

<style lang="scss">
html {
  font-size: 14px;
  min-width: 320px;
  overflow: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Microsoft YaHei,
    PingFang SC, miui, -apple-system, BlinkMacSystemFont, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}
</style>
