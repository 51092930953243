import { render, staticRenderFns } from "./CommentCard.vue?vue&type=template&id=79949bae&scoped=true"
import script from "./CommentCard.vue?vue&type=script&lang=ts"
export * from "./CommentCard.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./CommentCard.vue?vue&type=style&index=0&id=79949bae&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "79949bae",
  "d724c946"
  
)

export default component.exports