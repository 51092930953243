import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=25b43ad0&scoped=true"
import script from "./Info.vue?vue&type=script&lang=ts"
export * from "./Info.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Info.vue?vue&type=style&index=0&id=25b43ad0&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "25b43ad0",
  "718aa280"
  
)

export default component.exports