














































































































































































































































































































































import { Component, Vue } from "vue-property-decorator"
import memberDialog from "@/components/memberDialog"
import updateTdk, { formatTDK } from "@/utils/tdk"
import { inGhzs, urlScheme } from "@/utils/base"

@Component({
  name: "Bbs",
  components: {
    Backtop: () => import("@/components/backtop/backtop.vue"),
    HaloImage: () => import("@/components/image/image.vue"),
    OfficialBbsDetail: () => import("./components/OfficialBbsDetail.vue"),
    OpenAppButton: () => import("@/components/openAppButton/openAppButton.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Bbs extends Vue {
  get bbs() {
    return this.$store.state.bbs.bbs
  }

  isInGhzs = inGhzs()

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  activated() {
    if (!this.bbs) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  deactivated() {
    this.$store.commit("bbs/SET_BBS", { bbs: null })
  }

  fetchData() {
    return Promise.all([
      this.$store.dispatch("bbs/FETCH_BBS", { id: this.$route.params.bbs_id }),
      this.$root.$tstore.modules.bbs.actions.FETCH_SECTIONS({
        bbs_id: this.$route.params.bbs_id
      }),
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "bbs",
        _id_or_name: this.$route.params.bbs_id
      })
    ])
      .then(() => {
        this.formatData()

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{论坛名称}",
              replaceVal: this.bbs.name
            },
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.bbs.game?.name || ""
            }
          ])
        )
      })
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        updateTdk.call(this)
        this.$loading(false)
      })
  }
  formatData() {
    if (this.bbs.top) {
      this.bbs.top = this.bbs.top.filter(
        v => v.type === "community_article" || v.type === "article"
      )
    }
    this.$store.commit("bbs/SET_BBS_NAME", { bbsName: this.bbs.name })
  }

  /** 顶部 */
  isFixed = true
  onScroll({ scrollTop, isFixed }) {
    this.isFixed = scrollTop > 40
  }

  /** 是否展开 */
  isExpand = false

  /** 跳转到专区 */
  handleGoZone() {
    window.location.href = this.bbs.zone_tab.link
  }

  /** 专区和成员是否同时存在 */
  get showOther() {
    return (
      this.bbs &&
      this.bbs.moderator &&
      this.bbs.moderator.length > 0 &&
      this.bbs.zone_tab
    )
  }

  get noBg() {
    return this.bbs && !this.bbs.background
  }

  /** 打开成员弹窗 */
  handleMemberDialog() {
    memberDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          bbs_id: this.$route.params.bbs_id
        }
      },
      () => {}
    )
  }

  handleDownload() {
    // downloadDialog(
    //   {
    //     rmAfterDestroy: false,
    //     presetData: {}
    //   },
    //   () => {}
    // )
    this.$downloadDialog()
  }

  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }

  handleSearch() {
    this.$router.push(`/bbs/${this.$route.params.bbs_id}/search`)
  }

  createUrl(item) {
    if (item.type === "community_article") {
      return `/bbs/${item.link_community._id}/article/${item.link}`
    } else if (item.type === "article") {
      return `/article/${item.link}`
    }
    return "/"
  }
}
