import Api from "@/api/api"

const defaultIcon = "https://static-web.ghzs.com/official-site/logo.png"

export const tdk = {
  namespaced: true,
  state: () => ({
    title: null,
    keywords: null,
    description: null,
    icon: defaultIcon,
    caches: {}
  }),
  getters: {
    title: state => state.title,
    keywords: state => state.keywords,
    description: state => state.description,
    icon: state => state.icon,
    caches: state => state.caches
  },
  mutations: {
    SET_TDK: (state, { title, keywords, description }) => {
      if (title) {
        state.title = title
      }
      if (keywords) {
        state.keywords = keywords
      }
      if (description) {
        state.description = description
      }

      state.icon = defaultIcon
    },
    SET_CACHE: (state, { path, tdk }) => {
      state.caches[path] = tdk
    },
    SET_ICON: (state, { icon }) => {
      state.icon = icon
    }
  },
  actions: {
    FETCH_TDK: ({ commit }, { type, _id_or_name }) => {
      return Api.tdk.typeSeo(type, _id_or_name).then(res => {
        const { keyword, description, title } = res.data
        commit("SET_TDK", { keywords: keyword, description, title })
      })
    },
    FETCH_SUB_TDK: ({ commit }, { type, _id_or_name, sub_type }) => {
      return Api.tdk.subTypeSeo(type, _id_or_name, sub_type).then(res => {
        const { keyword, description, title } = res.data
        commit("SET_TDK", { keywords: keyword, description, title })
      })
    }
  }
}
