



















import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "UpdateText"
})
export default class UpdateText extends Vue {
  @Prop({ type: String, default: "" })
  text: string

  $refs: { content: HTMLElement }

  isFold = false
  get getContent() {
    const maxLen = Math.floor(this.$refs.content?.offsetWidth / 12) * 2 - 4
    return this.isFold ? this.text.slice(0, maxLen) + "..." : this.text
  }

  @Watch("text", { immediate: true })
  onText(text) {
    if (text) {
      this.$nextTick(() => {
        if (this.$refs.content?.offsetHeight > 18 * 2) {
          this.isFold = true
        }
      })
    }
  }
}
