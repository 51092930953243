






























































import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "HotBbsList",
  components: {}
})
export default class HotBbsList extends Vue {
  openIcon: string = require("@/assets/images/icon-展开内容.png")
  closeIcon: string = require("@/assets/images/icon-收起内容.png")

  isShowAll = false

  get HotBbsList() {
    return this.$root.$tstore.modules.bbs.getters.hotBbsList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.HotBbsList) {
      this.fetchData()
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.bbs.actions.FETCH_HOT_BBS_LIST()
  }

  // handleSelect(id: number) {
  //   this.$router.push({ path: `/bbs/${id}` })
  // }

  handleShowHide() {
    this.isShowAll = !this.isShowAll
  }

  formatHot(hot: number) {
    return hot && hot.toString().length >= 5
      ? hot.toString().slice(0, hot.toString().length - 4) + "万"
      : hot
  }
}
