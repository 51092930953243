



























import api from "@/api/api"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
interface Videos {
  _id: string
  poster: string
  title: string
  user: User
  vote: number
}
interface User {
  _id: string
  name: string
  icon: string
}
@Component({
  name: "Video",
  components: {
    VideoItem: () => import("./VideoItem/VideoItem.vue")
  }
})
export default class Video extends Vue {
  @Prop({ type: Array, default: () => [] })
  dataProps: Videos[]

  @Prop({ type: Number, default: 0 })
  count: number

  videos = []

  getVieoDetail() {
    Promise.all(this.dataProps.map(v => api.video.detail(v._id))).then(res => {
      this.videos = res.map(r => {
        return {
          poster: r.data.poster,
          url: r.data.url,
          video_id: r.data._id,
          author: r.data.user.name,
          vote: r.data.vote,
          title: r.data.title
        }
      })
    })
  }

  @Watch("dataProps", { immediate: true })
  onProp(data) {
    if (data) {
      this.getVieoDetail()
    }
  }
}
