





































































































































import { inPlugin } from "@/utils/base"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "downloadDialog"
})
export default class downloadDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    game_name: "",
    game_icon: ""
  }

  get hadGame() {
    return !!this.dialogDataPresets.game_name
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  handleDownload() {
    if (inPlugin()) {
      if (window.Java && window.Java.openInExternalApp) {
        window.Java.openInExternalApp(
          "https://download.ghzs.com/ghzs?channel=GH_112"
        )
      }
      this.onSubmit()
      return
    }
    window.open("https://download.ghzs.com/ghzs?channel=GH_112", "_blank")
    this.onSubmit()
  }

  @Watch("dialogVisible")
  onShow(show) {
    if (show) {
      if (window._launchAppUrl) {
        if (window.GhzsUa.UA.Wechat) {
          setTimeout(() => {
            const btn = document.querySelector<HTMLElement>("#launch-btn")
            if (btn) {
              var ev = document.createEvent("Event")
              // @ts-ignore
              ev.initEvent("launch", true, true)
              btn.dispatchEvent(ev)
            }
          }, 100)
        } else if (window.GhzsUa.UA.Android) {
          window.open(window._launchAppUrl, "_blank")
        }
      }
    }
  }
}
