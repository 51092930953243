import { render, staticRenderFns } from "./VideoItem.vue?vue&type=template&id=08ed5c0b&scoped=true"
import script from "./VideoItem.vue?vue&type=script&lang=ts"
export * from "./VideoItem.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./VideoItem.vue?vue&type=style&index=0&id=08ed5c0b&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "08ed5c0b",
  "b9b3fc2c"
  
)

export default component.exports