import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=1c96f1e5&scoped=true"
import script from "./Header.vue?vue&type=script&lang=ts"
export * from "./Header.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Header.vue?vue&type=style&index=0&id=1c96f1e5&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1c96f1e5",
  "2d5231de"
  
)

export default component.exports