import { MColumn } from "@/api/modules/column"
import api from "@/api/api"
import { isProdDeploy } from "@/config"

interface IState {
  list: IRankingDataItem[]
  selectedColumnId: string
}

export const ranking = {
  namespaced: true,
  state: () => ({
    list: null,
    selectedColumnId: null
  }),
  getters: {
    list: (state: IState) => state.list,
    selectedColumn: (state: IState) => {
      return state.list
        ? state.list.filter(v => v.column_id === state.selectedColumnId)[0]
        : null
    }
  },
  mutations: {
    SET_LIST: (state: IState, { list }) => {
      state.list = list
    },
    SET_SELECTED_COLUMN_ID: (state: IState, { column_id }) => {
      state.selectedColumnId = column_id
    }
  },
  actions: {
    FETCH_LIST: ({ commit }, { path }) => {
      return new Promise(async (resolve, reject) => {
        try {
          // const res = await api.ranking.list()
          const list: IRankingDataItem[] = JSON.parse(
            JSON.stringify(createList())
          ).map(v => {
            v.page = 1
            v.data = []
            v.page_size = 30
            v.total = null
            v.finished = false
            return v
          })
          const curIdx =
            list.map(v => v.path).indexOf(path) > -1
              ? list.map(v => v.path).indexOf(path)
              : 0

          const { column_id, page, page_size } = list[curIdx]
          const result = await api.column.columnGames(column_id, {
            page,
            page_size
          })
          list[curIdx].data = result.data
          list[curIdx].page++
          list[curIdx].total = parseInt(result.headers.total)
          if (result.data.length >= list[curIdx].total) {
            list[curIdx].finished = true
          }

          commit("SET_LIST", { list })
          commit("SET_SELECTED_COLUMN_ID", { column_id })
          resolve("")
        } catch (error) {
          console.log(error)
        }
      })
    }
  }
}

export interface IRankingDataItem {
  _id: string
  column_id: string
  title: string
  data: MColumn.IColumnGameItem[]
  page: number
  page_size: number
  total: number
  finished: boolean
  path?: string
}

function createList() {
  return isProdDeploy
    ? [
        {
          _id: "5de4c8acff13f60aa534971e",
          column_id: "5de4c8acff13f60aa534971e",
          title: "热门榜",
          path: "hot"
        },
        {
          _id: "5de20e17d3b73704643ad0a3",
          column_id: "5de20e17d3b73704643ad0a3",
          title: "单机榜",
          path: "stand-alone"
        },
        {
          _id: "5de4c9f4ff13f60a9754065d",
          column_id: "5de4c9f4ff13f60a9754065d",
          title: "新品榜",
          path: "new"
        },
        {
          _id: "5fec38cdef7f553ecc0045ce",
          column_id: "5fec38cdef7f553ecc0045ce",
          title: "模拟器",
          path: "simulator"
        },
        {
          _id: "5f22a78057336d7ef62c4fab",
          column_id: "5f22a78057336d7ef62c4fab",
          title: "预约榜",
          path: "expect"
        }
      ]
    : [
        {
          _id: "61022822f1de544bcb163f12",
          column_id: "61022822f1de544bcb163f12",
          title: "热门榜",
          path: "hot"
        },
        {
          _id: "5fd72f98d05af6091c5386b5",
          column_id: "5fd72f98d05af6091c5386b5",
          title: "单机榜",
          path: "stand-alone"
        },
        {
          _id: "5de4ab7379aae9071a788c34",
          column_id: "5de4ab7379aae9071a788c34",
          title: "新品榜",
          path: "new"
        },
        {
          _id: "5fd081b54c41561e12241b7d",
          column_id: "5fd081b54c41561e12241b7d",
          title: "模拟器",
          path: "simulator"
        },
        {
          _id: "5de76760b1180c012578eab2",
          column_id: "5de76760b1180c012578eab2",
          title: "预约榜",
          path: "expect"
        }
      ]
}
