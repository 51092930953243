import Vue from "vue"
import Vuex from "vuex"
import { content } from "./modules/content"
import { article } from "./modules/article"
import { communityArticle } from "./modules/communityArticle"
import { bbs } from "./modules/bbs"
import { gameList } from "./modules/gameList"
import { position } from "./modules/position"
import { tdk } from "./modules/tdk"
import { toolkit } from "./modules/toolkit"
import { basic } from "./modules/basic"
import { ranking } from "./modules/ranking"
import { novel } from "./modules/novel"
import { column } from "./modules/column"
import { home } from "./modules/home"
import { ques } from "./modules/ques"
Vue.use(Vuex)

import TStore from "vuex-tstore"

export default function createStore() {
  const $tstore = new TStore.Store({
    modules: {
      content,
      article,
      communityArticle,
      bbs,
      gameList,
      position,
      tdk,
      toolkit,
      basic,
      ranking,
      novel,
      column,
      home,
      ques
    },
    // IMPORTANT: state must be a function so the module can be
    // instantiated multiple times
    state: () => ({}),
    mutations: {},
    actions: {}
  })

  return $tstore
}
