import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=b40960b2&scoped=true"
import script from "./footer.vue?vue&type=script&lang=ts"
export * from "./footer.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./footer.vue?vue&type=style&index=0&id=b40960b2&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b40960b2",
  "03715886"
  
)

export default component.exports