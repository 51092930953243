















































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { copyText } from "@/utils/base"
@Component({
  name: "shareDialog"
})
export default class shareDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {}

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  qZone(title, pic) {
    // var p = {
    //   url:
    //     "http://192.168.5.23:8083/bbs/5b6952e02924bc8e2de8324a/article/6074ff6efaff57001c0e0742",
    //   showcount: "1" /*是否显示分享总数,显示：'1'，不显示：'0' */,
    //   desc: "这篇文章不错,分享一下~~" /*默认分享理由(可选)*/,
    //   summary: "" /*分享摘要(可选)*/,
    //   title: "123123" /*分享标题(可选)*/,
    //   site: "光环助手" /*分享来源 如：腾讯网(可选)summary*/,
    //   pics: "" /*分享图片的路径(可选)*/,
    //   style: "101",
    //   width: 199,
    //   height: 30
    // }
    // var s = []
    // for (var i in p) {
    //   s.push(i + "=" + encodeURIComponent(p[i] || ""))
    // }
    // var target_url =
    //   "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" +
    //   s.join("&")
    // window.open(target_url, "qZone", "height=430, width=400")
    window.location.href =
      "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey"
  }

  sinaWeiBo(title, url, pic) {
    // const share = {
    //   title: "分享标题",
    //   image_url: "图片URL",
    //   share_url: "分享链接"
    // }
    // location.replace(
    //   "https://service.weibo.com/share/share.php?url=" +
    //     encodeURIComponent(share.share_url) +
    //     "&title=" +
    //     share.title +
    //     "&appkey=277159429&&ralateUid=1855112015&pic=" +
    //     share.image_url +
    //     "&searchPic=true"
    // )
    window.location.href = "https://service.weibo.com/share/share.php"
  }

  copy() {
    copyText(window.location.origin + window.location.pathname, () => {
      this.$toast(`链接已复制`)
      this.onSubmit()
    })
  }

  // 修复overflow hidden不起作用
  scrollTopVal: number = 0
  @Watch(`dialogVisible`)
  onChangeDialogShow(change: any) {
    if (change) {
      this.scrollTopVal =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
      document.body.style.overflow = "hidden"
      document.documentElement.style.position = "fixed"
      document.body.style.position = "fixed"
      document.documentElement.style.top = -1 * this.scrollTopVal + "px"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.position = ""
      document.body.style.position = ""
      window.scrollTo(0, this.scrollTopVal)
    }
  }
}
