














import { IToolkitList } from "@/api/modules/toolkit"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Card",
  components: {
    CardInnerList: () => import("../cardInnerList/cardInnerList.vue")
  }
})
export default class Card extends Vue {
  @Prop({ type: Object, required: true }) data: IToolkitList
}
