import { render, staticRenderFns } from "./Server.vue?vue&type=template&id=49955134&scoped=true"
import script from "./Server.vue?vue&type=script&lang=ts"
export * from "./Server.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Server.vue?vue&type=style&index=0&id=49955134&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "49955134",
  "4ca01460"
  
)

export default component.exports