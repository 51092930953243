
























import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Search",
  beforeRouteLeave(to, from, next) {
    if (to.name === "searchResult" && to.params.keyword) {
      this.showTip = false
      this.keyword = to.params.keyword
      this.resultKey++
    }
    if (to.name === "search") {
      this.showTip = true
      this.keyword = ""
    }
    next()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.keyword) {
      this.showTip = false
      this.keyword = to.params.keyword
      this.resultKey++
    }
    next()
  },
  components: {
    Footer: () => import("@/components/footer/footer.vue"),
    SearchTip: () => import("./components/SearchTip/SearchTip.vue"),
    SearchResult: () => import("./components/SearchResult/SearchResult.vue")
  }
})
export default class Search extends Vue {
  keyword = ""
  tipKey = 0
  resultKey = 0
  showTip = true

  // ssr hook
  serverPrefetch() {
    this.init()
    return Promise.resolve()
  }

  mounted() {
    this.init()
  }

  init() {
    if (this.$route.params.keyword) {
      this.showTip = false
      this.keyword = this.$route.params.keyword
    }
  }

  handleEnter() {
    if (
      this.keyword.length > 0 &&
      this.keyword !== this.$route.params.keyword
    ) {
      this.updateHistory(this.keyword)
      this.$store.commit("gameList/SET_GAME_SEARCH_LIST", {
        gameSearchList: null
      })
      this.$router.push({ path: `/search/${this.keyword}` })
    }
  }

  updateHistory(keyword) {
    const HISTORY_LIST = localStorage.getItem("HISTORY_LIST")
      ? JSON.parse(localStorage.getItem("HISTORY_LIST"))
      : null
    if (HISTORY_LIST) {
      if (HISTORY_LIST.includes(keyword)) {
        return
      }

      if (HISTORY_LIST.length < 5) {
        HISTORY_LIST.unshift(keyword)
      } else {
        HISTORY_LIST.pop()
        HISTORY_LIST.unshift(keyword)
      }
      localStorage.setItem("HISTORY_LIST", JSON.stringify(HISTORY_LIST))
    } else {
      localStorage.setItem("HISTORY_LIST", JSON.stringify([keyword]))
    }
    this.tipKey++
  }

  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }
}
