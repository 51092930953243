




































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator"
import { ImagePreview } from "vant"
import api from "@/api/api"
import { useContentApi } from "@/api/useContentApi"
import {
  getPluginVersion,
  getUserToken,
  inPlugin,
  checkPluginLogin,
  formatURLInHTML
} from "@/utils/base"
import commentDialog from "@/components/commentDialog"

@Component({
  name: "Comment",
  components: {
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),

    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  }
})
export default class Comment extends Vue {
  formatURLInHTML = formatURLInHTML

  get detail() {
    return this.$store.state.communityArticle.detail
  }

  get commentList() {
    return this.$store.state.communityArticle.commentList
  }

  page = 1
  pageSize = 20
  loading = false
  finished = false
  list = []
  nextList = []

  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }

    const res = await api.communityArticle.comments(
      "",
      this.$route.params.article_id,
      {
        view: "bbs_web",
        page: this.page,
        page_size: this.pageSize,
        sort: this.sort
      }
    )
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(res.data)
      this.prefetchNextPage()
    }
  }
  async prefetchNextPage() {
    const nextRes = await api.communityArticle.comments(
      "",
      this.$route.params.article_id,
      {
        view: "bbs_web",
        page: this.page + 1,
        page_size: this.pageSize,
        sort: this.sort
      }
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data
    }
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (this.isInPlugin && this.isPluginLogin) {
      this.loading = true
      this.fetchData()
        .then(res => {
          this.list = this.commentList
          this.prefetchNextPage()
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      if (this.commentList) {
        this.list = this.commentList
        this.prefetchNextPage()
      } else {
        this.loading = true
        this.fetchData()
          .then(res => {
            this.list = this.commentList
            this.prefetchNextPage()
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }

  fetchData() {
    return this.$store.dispatch("communityArticle/FETCH_COMMENT_LIST", {
      bbs_id: this.$route.params.bbs_id,
      article_id: this.$route.params.article_id,
      params: {
        view: "bbs_web",
        page: this.page,
        pageSize: this.pageSize,
        sort: this.sort
      }
    })
  }

  destroyed() {
    this.$store.commit("communityArticle/SET_COMMENT_LIST", {
      commentList: null
    })
  }

  sortType = 1 // 1 -1
  get sort() {
    return `time.create:${this.sortType}`
  }

  handleDownload() {
    if (this.isInPlugin) {
      return
    }
    this.$downloadDialog()
  }

  @Watch("commentList")
  onCommentList(c) {
    if (c && c.length === 0) {
      this.finished = true
    }
  }

  @Watch("sortType")
  onType(c) {
    this.page = 1
    this.list = []
    this.nextList = []
    this.loading = true
    this.finished = false
    this.fetchData()
      .then(() => {
        this.list = this.commentList
        this.prefetchNextPage()
      })
      .finally(() => {
        this.loading = false
      })
  }

  preview(images, idx) {
    ImagePreview({
      images,
      startPosition: idx
    })
  }

  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()
  async handleVote(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }

      const isVote = item?.me?.is_community_article_comment_voted || false

      const api = useContentApi(getUserToken())
      if (isVote) {
        try {
          await api.unvote_comment.communityArticle(item._id)
          this.$set(item, "me", {
            ...item.me,
            is_community_article_comment_voted: false
          })
          if (item.vote) {
            item.vote--
          } else {
            item.vote = 0
          }
        } catch (error) {
          console.error(error)
          this.$toast("取消点赞失败")
        }
      } else {
        const api = useContentApi(getUserToken())
        try {
          await api.vote_comment.communityArticle(item._id)
          this.$set(item, "me", {
            ...item.me,
            is_community_article_comment_voted: true
          })
          if (item.vote) {
            item.vote++
          } else {
            item.vote = 1
          }
        } catch (error) {
          console.error(error)
          this.$toast("点赞失败")
          // this.$toast("点赞失败 a_id" + this.$route.params.article_id)
          // setTimeout(() => {
          //   this.$toast("点赞失败1 id" + item._id)
          // }, 3000)
          // setTimeout(() => {
          //   this.$toast("点赞失败2 token" + getUserToken() || "no")
          // }, 6000)
        }
      }
      return
    }

    this.$downloadDialog()
  }

  handleComment(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const api = useContentApi(getUserToken())
      commentDialog(
        {
          rmAfterDestroy: true,
          presetData: {}
        },
        async ({ content }) => {
          try {
            await api.reply_comment.communityArticle(item._id, { content })
            this.refresh()
          } catch (error) {
            console.log(error)
            this.$toast("评论失败")
          }
        }
      )
      return
    }
    this.$downloadDialog()
  }

  refresh() {
    this.page = 1
    this.list = []
    this.nextList = []
    this.fetchData()
      .then(res => {
        this.list = this.commentList
        this.prefetchNextPage()
      })
      .finally(() => {
        this.loading = false
      })
  }
}
