import { render, staticRenderFns } from "./shareDialog.vue?vue&type=template&id=ca0d2268&scoped=true"
import script from "./shareDialog.vue?vue&type=script&lang=ts"
export * from "./shareDialog.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./shareDialog.vue?vue&type=style&index=0&id=ca0d2268&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "ca0d2268",
  "3b367ca6"
  
)

export default component.exports