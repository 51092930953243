class SingletonCache {
  private _isFullscreen = false

  get isFullscreen() {
    return this._isFullscreen
  }

  setIsFullscreen(payload: boolean) {
    this._isFullscreen = payload
  }
}

export class ProxySingletonCache {
  static instance: SingletonCache = null
  constructor() {
    if (!ProxySingletonCache.instance) {
      ProxySingletonCache.instance = new SingletonCache()
    }
    return ProxySingletonCache.instance
  }

  static getInstance(): SingletonCache {
    if (!ProxySingletonCache.instance) {
      ProxySingletonCache.instance = new SingletonCache()
    }
    return ProxySingletonCache.instance
  }
}

type Observer = {
  name: string
  list: any
  subscribe: (key: string, fn: Function) => void
  trigger: (key: string, ...arg) => void
  unsubscribe: (key: string, fn: Function | boolean) => void
} & { [key: string]: any }

export const observer: Observer = Object.create({})
// 订阅池
observer.list = {}
observer.name = "MicroObserver"
// 订阅
observer.subscribe = function(key, fn) {
  if (!this.list[key]) {
    this.list[key] = []
  }
  this.list[key].push(fn)
}

observer.trigger = function(key, ...args) {
  const fns = this.list[key]
  if (!fns) {
    return
  }
  for (let i = 0; i < fns.length; i++) {
    fns[i](...args)
  }
}

observer.unsubscribe = function(key, fn) {
  if (typeof fn === "boolean" && fn === true) {
    this.list[key] = null
    return
  }

  const fns = this.list[key]
  this.list[key] = fns.filter(item => {
    return item !== fn
  })
}
