








































import { createArticleLink } from "@/config"
import { DateFotmat } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "Article",
  components: {}
})
export default class Article extends Vue {
  @Prop({ type: Array, default: () => [] })
  articles: any[]

  @Prop({ type: Array, default: () => [] })
  articleTops: any[]

  DateFotmat = DateFotmat

  handleArticle(id) {
    window.open(createArticleLink(id))
  }
}
