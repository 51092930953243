




























import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "RelatedVersion",
  components: {}
})
export default class RelatedVersion extends Vue {
  @Prop({ type: Array, default: () => [] })
  versions: any[]

  getStyle(color, background) {
    return `color:${color};background-color:${background};`
  }
}
