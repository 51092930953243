












































import { MColumn } from "@/api/modules/column"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "GameItem",
  components: {
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue")
  }
})
export default class GameItem extends Vue {
  @Prop({ type: Object, default: "" }) item: MColumn.IColumnGameItem
  @Prop({ type: Number, default: 0 }) idx

  get filterTags() {
    return this.$root.$tstore.modules.gameList.getters.filterTags
  }

  get new_tag_style() {
    return this.filterTags && this.filterTags.length > 0
      ? this.item.new_tag_style.filter(v => !this.filterTags.includes(v.name))
      : this.item.new_tag_style
  }
}
