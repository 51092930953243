





































import { Component, Vue, Prop } from "vue-property-decorator"
import shareDialog from "@/components/shareDialog"
import downloadDialog from "@/components/downloadDialog"
import commentDialog from "@/components/commentDialog"

import {
  appBoundle,
  getUserToken,
  inPlugin,
  checkPluginLogin
} from "@/utils/base"
const { openApp } = appBoundle()

@Component({
  name: "CommentInput"
})
export default class CommentInput extends Vue {
  @Prop({ required: false, default: false })
  isVote!: boolean

  @Prop({ required: false, default: false })
  isStar!: boolean

  @Prop({ required: false, default: true })
  showVoteBtn!: boolean

  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()

  handleInput() {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      commentDialog(
        {
          rmAfterDestroy: true,
          presetData: {}
        },
        ({ content }) => {
          this.$emit("comment", content)
        }
      )
      return
    }
    openApp(window._launchAppUrl, function() {
      downloadDialog(
        {
          rmAfterDestroy: false,
          presetData: {}
        },
        () => {}
      )
    })
  }

  handleStar() {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      this.$emit("star", !this.isStar)
      return
    }
    openApp(window._launchAppUrl, function() {
      downloadDialog(
        {
          rmAfterDestroy: false,
          presetData: {}
        },
        () => {}
      )
    })
  }

  handleVote() {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      this.$emit("vote", !this.isVote)
      return
    }
    openApp(window._launchAppUrl, function() {
      downloadDialog(
        {
          rmAfterDestroy: false,
          presetData: {}
        },
        () => {}
      )
    })
  }

  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }
}
