


























































































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
import { TableData } from "@/utils/base"
import shareDialog from "@/components/shareDialog"
import { inGhzs, urlScheme, inPlugin, getUserToken } from "@/utils/base"
import { useContentApi } from "@/api/useContentApi"

@Component({
  name: "VideoPage",
  components: {
    Info: () => import("./components/Info.vue"),
    Comments: () => import("./components/Comments.vue"),
    Recommends: () => import("./components/Recommends.vue"),

    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue"),
    FloatDownload: () => import("@/components/floatDownload/floatDownload.vue"),
    CommentInput: () => import("@/components/CommentInput"),
    OpenAppButton: () => import("@/components/openAppButton/openAppButton.vue")
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchData(to.params.video_id)
    this.reset()
    next()
  },
  tdk() {
    return this.tdk
  }
})
export default class VideoPage extends Vue {
  $refs: { dplayerVideo: any; comments: any }

  isInGhzs = inGhzs()
  isInPlugin = inPlugin()

  isPlaying = false

  options = {
    screenshot: false,
    video: {
      _id: "",
      url: "",
      pic: "",
      type: "auto"
    }
  }

  get videoDetail() {
    return this.$root.$tstore.modules.content.getters.videoDetail
  }

  get videoRecommends() {
    return this.$root.$tstore.modules.content.getters.videoRecommends
  }

  get videoComments() {
    return this.$root.$tstore.modules.content.getters.videoComments
  }

  get gameName() {
    let name = ""
    if (this.videoDetail.game) {
      name = this.videoDetail.game.name
    }
    if (this.videoDetail.bbs && this.videoDetail.bbs.name) {
      name = this.videoDetail.bbs.name
    }
    return name
  }
  get tdk() {
    const tdk = { title: null, keywords: null, description: null }
    if (this.videoDetail) {
      tdk.title = `${this.videoDetail.title}-${this.gameName}视频-光环助手视频`
      tdk.keywords = `${this.videoDetail.title}，${this.gameName}视频，${this.gameName}，光环助手视频`
      tdk.description = `${this.gameName}论坛为您提供${this.gameName}关于${this.videoDetail.title}的相关视频，在光环助手网页版，有关${this.gameName}的一切应有尽有，最多最全攻略手游论坛！`
    }
    return tdk
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.videoDetail) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }
  timer = null
  mounted() {
    this.reset()
  }

  reset() {
    this.setCommentSort()
    this.isPlaying = false
  }

  handlePlay() {
    this.options = {
      screenshot: false,
      video: {
        _id: this.videoDetail._id,
        url: this.videoDetail.url,
        pic: this.videoDetail.poster,
        type: "auto"
      }
    }
    this.isPlaying = true
  }

  setCommentSort() {
    this.$refs.comments && this.$refs.comments.reset()
  }

  fetchData(id?) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.content.actions.FETCH_VIDEO_DETAIL({
          id: id || this.$route.params.video_id
        })

        this.formatData()

        updateTdk.call(this)

        resolve("")
      } catch (error) {
        reject(error)
      }
    })
  }

  formatData() {
    if (!this.$isServer) {
      this.isVote = this.videoDetail?.me?.is_voted || false
      this.isStar = this.videoDetail?.me?.is_video_favorite || false

      window._launchAppUrl = urlScheme.video(
        this.videoDetail._id,
        this.videoDetail.game_id,
        encodeURI(window.location.href)
      )
    }
  }

  onLoad(c) {
    // this.image = ""
    ;(this.$el.querySelector(".dplayer-setting") as HTMLElement).style.display =
      "none"
    this.isPlaying = true
    // 这里设置声音会导致播放暂停
    // this.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
  }

  destroyed() {
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_DETAIL(null)
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_COMMENTS(
      new TableData({})
    )
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_RECOMMENDS(null)
  }
  /** 顶部 */
  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }
  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }
  handleSearch() {
    this.$router.push("/search")
  }

  showFloatDownload = !(this.isInGhzs || this.isInPlugin)
  // 关闭下载提示条
  handleCloseFloatDownLoad() {
    this.showFloatDownload = false
  }

  isVote = false
  async onVote(change) {
    console.log("onVote")
    const api = useContentApi(getUserToken())
    if (change) {
      try {
        await api.vote.video(this.videoDetail._id)
        this.isVote = change
        this.$toast("操作成功")
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await api.unvote.video(this.videoDetail._id)
        this.isVote = change
        this.$toast("操作成功")
      } catch (error) {
        console.error(error)
      }
    }
  }

  isStar = false
  async onStar(change) {
    console.log("onStar", change)
    const api = useContentApi(getUserToken())
    if (change) {
      try {
        await api.favorites.video(this.videoDetail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await api.cancel_favorites.video(this.videoDetail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    }
  }

  commentKey = 0
  async onComment(content) {
    const api = useContentApi(getUserToken())
    try {
      await api.comment.video(this.videoDetail._id, { content })
      this.fetchData()
    } catch (error) {
      console.error(error)
    }
  }
}
