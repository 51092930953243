import { apiApp, apiBaseUrl, apiBaseUrlV5_5, apiBaseWikiUrl } from "@/config"
import { AxiosPromise, AxiosResponse } from "axios"
import http from "./http"
const service = http.service

service.defaults.baseURL = apiBaseUrl

/**
 * @description 文档：https://git.ghzs.com/halo/wiki/and-wiki/-/wikis/api/v4.8.0/%E5%86%85%E5%AE%B9%E4%B8%BB%E7%AB%99
 * @description 文档-游戏相关: https://git.ghzs.com/halo/wiki/and-wiki/-/wikis/api/v4.9.0/%E5%86%85%E5%AE%B9%E4%B8%BB%E7%AB%99%EF%BC%88%E7%AC%AC%E4%BA%8C%E6%9C%9F%EF%BC%89
 */
import toolkit from "./modules/toolkit"
import basic from "./modules/basic"
import ranking from "./modules/ranking"
import novel from "./modules/novel"
import column from "./modules/column"
import video from "./modules/video"
import question from "./modules/question"
import { getUserToken, inPlugin, checkPluginLogin } from "@/utils/base"

const api = {
  bbs: {
    // 获取论坛列表
    // 可选：参数category_id，分类ID用于获取该分类下的论坛
    list(params?: { category_id: string }) {
      return service.get(`/bbses`, { params })
    },
    // 获取论坛详情
    detail(bbs_id: string) {
      return service.get(`/bbses/${bbs_id}`)
    },
    // 获取论坛版主
    moderators(bbs_id: string) {
      return service.get(`/bbses/${bbs_id}/moderators`)
    },
    // 获取论坛热门列表
    hotBbses() {
      return service.get(`/bbses:hot?page=1&page_size=100`)
    },
    // 官方论坛列表 https://git.shanqu.cc/halo/wiki/and-wiki/-/wikis/api/v5.0.0/API-v5.0.0#get-bbsesofficial
    officialList() {
      return service.get(`/bbses:official`)
    },
    // 论坛首页推荐帖子列表
    recommendContents(params): AxiosPromise<IRecommendContentItem[]> {
      return service.get(`/bbses/recommends/contents`, { params })
    },
    // 获取子板块列表
    sectionsList(bbs_id: string) {
      return service.get(`${apiApp}/bbses/${bbs_id}/sections`)
    }
  },
  content: {
    // 论坛内容详情搜索
    search(params: {
      keyword: string
      bbs_id?: string
      type?: string
      page?: number
    }) {
      return service.get(`/bbses:search`, { params })
    },
    // 论坛名字
    searchName(params: {
      keyword: string
      bbs_id?: string
      type?: string
      page?: number
    }) {
      return service.get(`/bbses:search_name`, { params })
    },
    // 获取论坛全部Tab内容（社区文章+问题）
    list(
      bbs_id: string,
      params?: {
        filter?: string
        sort: "time.create:-1" | "time.reply:-1"
        type: string
        page: number
        page_size: number
      }
    ): Promise<AxiosResponse<MBbsContent.IContent[]>> {
      return service.get(`${apiBaseUrlV5_5}/bbses/${bbs_id}/contents`, {
        params
      })
    },
    // 获取论坛精华Tab内容（社区文章）
    jinghuaList(
      bbs_id: string,
      params?: { page: number; page_size: number }
    ): Promise<AxiosResponse<MBbsContent.IArticle[]>> {
      return service.get(
        `/bbses/${bbs_id}/jinghua?filter=type:community_article`,
        { params }
      )
    },
    // 获取论坛问答Tab内容（问题）
    questionsList(
      bbs_id: string,
      params?: { page: number; page_size: number; sort?: string }
    ): Promise<AxiosResponse<MBbsContent.IQues[]>> {
      return service.get(`/bbses/${bbs_id}/questions?view=web`, { params })
    },
    videoList(
      bbs_id: string,
      params?: { page: number; page_size: number; sort: string }
    ): Promise<AxiosResponse<MBbsContent.IVideoListItem[]>> {
      return service.get(`/bbses/${bbs_id}/videos`, { params })
    }
  },
  communityArticle: {
    // 获取论坛帖子详情
    detail(community_id: string, article_id: string) {
      const path = community_id
        ? `/communities/${community_id}/articles/${article_id}`
        : `/communities/articles/${article_id}`
      return service.get(path, {
        params: { view: "detail" }
      })
    },
    // 获取帖子评论列表
    comments(
      community_id: string,
      article_id: string,
      params?: {
        view: "bbs_web"
        page_size?: number
        page?: number
        sort?: string
      }
    ) {
      let headers = {}
      if (inPlugin() && checkPluginLogin()) {
        headers = {
          token: getUserToken()
        }
      }
      const path = community_id
        ? `/communities/${community_id}/articles/${article_id}/comments`
        : `/communities/articles/${article_id}/comments`
      return service.get(path, { params, headers })
    }
  },
  article: {
    // 获取游戏文章详情
    detail(article_id: string) {
      let headers = {}
      if (inPlugin() && checkPluginLogin()) {
        headers = {
          token: getUserToken()
        }
      }
      return service.get(`/articles/${article_id}`, {
        params: { view: "web" },
        headers
      })
    },
    // 获取评论列表
    comments(
      article_id: string,
      params?: {
        page_size?: number
        page?: number
      }
    ) {
      let headers = {}
      if (inPlugin() && checkPluginLogin()) {
        headers = {
          token: getUserToken()
        }
      }
      return service.get(`/articles/${article_id}/comments`, {
        params,
        headers
      })
    },
    // 获取文章评论总数
    count(article_id: string) {
      return service.get(`/articles/${article_id}/comments:count`)
    }
  },
  games: {
    /** 首页-游戏列表 */
    homeContentList(params: IListParams) {
      return service.get(`/home/contents`, { params })
    },
    /** 首页-专题列表 热门文章 标签 */
    homeList(): Promise<AxiosResponse<MHome.IHomeData>> {
      return service.get(`${apiApp}/web_app/home`)
    },
    /**
     * 游戏详情
     * @param game_id 游戏id
     * @param view web_digest: 游戏摘要（内容主站） ｜ web_detail: 游戏详情（内容主站）
     */
    detail(game_id: string, view?: string) {
      return service.get(`/games/${game_id}`, {
        params: { view: view || "web_digest" }
      })
    },
    /** 首页-专题，近期游戏速报 */
    indexGameList(params: IListParams) {
      return service.get(`/columns/5fababc160ae38014c0e959a/index_games`, {
        params
      })
    },
    /** 首页-专题，光环推荐 */
    recommendList(params: IListParams) {
      return service.get(`/columns/5de4ab7379aae9071a788c34/games`, { params })
    },
    /** 热门搜索 */
    hotSearches(params: { view: string }) {
      return service.get(`/settings/searches/remen`, { params })
    },
    /** 搜索游戏 */
    gameSearch(params: IListParams) {
      return service.get(`/games:search`, { params })
    },
    /** 评分信息 */
    gameRating(game_id: string) {
      return service.get(`/games/${game_id}/rating`)
    },
    /** 游戏评论 */
    gameComments(game_id: string, params: IListParams) {
      return service.get(`/games/${game_id}/comments`, { params })
    },
    /** 评论详情 */
    gameCommentDetail(game_id: string, comment_id: string) {
      return service.get(`/games/${game_id}/comments/${comment_id}`)
    },
    /** 全部回复 */
    gameCommentReplies(
      game_id: string,
      comment_id: string,
      params: IListParams
    ) {
      return service.get(`/games/${game_id}/comments/${comment_id}/replies`, {
        params
      })
    },
    filterTags() {
      return service.get("/games/filter_detail_tags")
    },
    /** 游戏大事件 */
    gameEvents(game_id: string) {
      return service.get(`/games/${game_id}/events`)
    }
  },
  libao: {
    getStatus(params: { filter: string }) {
      return service.get(`/libao/status`, { params })
    }
  },
  video: {
    detail(video_id: string) {
      return service.get(`/videos/${video_id}?view=web`)
    }
  },
  /** 标签列表 */
  tag: {
    tags(params: {
      filter: string
      sort: string
      view: "web"
    }): Promise<AxiosResponse<NTagSearch.IItem[]>> {
      return service.get(`/games`, { params })
    }
  },
  tdk: {
    typeSeo(type: string, _id_or_name: string) {
      return service.get(`${apiApp}/web_app/seo/${type}/${_id_or_name}`)
    },
    subTypeSeo(type: string, _id_or_name: string, sub_type: string) {
      return service.get(
        `${apiApp}/web_app/seo/${type}/${_id_or_name}/${sub_type}`
      )
    }
  },
  ...toolkit(service),
  ...basic(service),
  ...ranking(service),
  ...column(service),
  ...novel(service),
  ...video(service),
  ...question(service)
}

export default api

export interface IListParams {
  page: number
  page_size?: number
  view?: string
  random?: string
  keyword?: string
  filter?: string
  sort?: string
}

export declare module MHome {
  interface IHomeData {
    columns: IColumn[]
    tags: ITag[]
    contents: IContent[]
  }

  interface IContent {
    _id: string
    title: string
    count: ICount
    bbs?: IBb
    game?: { name: string; _id: string }
  }

  interface IBb {
    _id: string
    name: string
  }

  interface ICount {
    vote: number
    comment: number
  }

  interface ITag {
    _id: string
    name: string
  }

  interface IColumn {
    name: string
    _id: string
    display: "manual" | "auto" | "timeline"
    games: IGam[]
    _seq: string
  }

  interface IGam {
    _id: string
    name: string
    name_suffix: string
    category: string
    brief: string
    icon: string
    ori_icon: string
    assign_remark?: IAssign_remark
    _seq: string
  }

  interface IAssign_remark {
    first_line: string
    marked_red: boolean
    recommend: boolean
    second_line: string
    start_time: number
    end_time: number
  }
}

export declare module MBbsContent {
  type IContent = IArticle | IVideo | IQues

  interface IArticle {
    _id: string
    title: string
    brief: string
    images: string[]
    images_info: IImages_info[]
    videos: void /* undefined */[]
    user: IUser
    time: number
    status: string
    _seq: number
    count: ICount
    bbs: IBb
    type: "community_article" | "bbs_article"
  }

  interface IVideo {
    _id: string
    title: string
    des: string
    poster: string
    url: string
    length: number
    user: IUser
    video_info: IVideo_info
    time: number
    count: ICount
    bbs: IBb
    type: "video"
    _seq: number
  }

  interface ICount {
    vote: number
    comment: number
    favorite?: number
  }

  interface IVideo_info {
    width: number
    height: number
  }

  interface IBb {
    _id: string
    name: string
    type: string
    _seq: number
    game: IGame
  }

  interface IGame {
    _id: string
    icon: string
    icon_subscript: string
    ori_icon: string
    _seq: number
  }

  interface IUser {
    _id: string
    name: string
    icon: string
    badge?: IBadge
  }
  interface IBadge {
    name: string
    icon: string
    actions: {
      text: string
    }
  }

  interface IImages_info {
    width: number
    height: number
  }

  interface IVideoListItem {
    _id: string
    title: string
    poster: string
    url: string
    length: number
    user: IUser
    time: number
    video_info: IVideo_info
    count: ICount
    bbs: IBb
    game: IGame_1
    type: "bbs_video"
    _seq: number
  }

  interface IGame_1 {
    _id: string
    name: string
    name_suffix: string
    icon: string
    ori_icon: string
  }

  interface IQues {
    _seq: string
    _id: string
    title: string
    description: string
    images: void /* undefined */[]
    images_info: void /* undefined */[]
    videos: void /* undefined */[]
    user: {
      _id: string
      name: string
      icon: string
      icon_border_url: string
      count: {
        fans: number
      }
    }
    time: number
    status: string
    count: {
      answer: number
      reply: number
    }
    type: "bbs_question" | "question"
    bbs: IBb
  }
}

export namespace NTagSearch {
  export interface IItem {
    _id: string
    name: string
    name_suffix: string
    category: string
    brief: string
    icon: string
    ori_icon: string
    star: number
    show_comment: boolean
    comment_count: number
    new_star: number
    tag_style: ITag_style[]
    new_tag_style: INew_tag_style[]
    _seq: number
    tag: string[]
  }

  interface INew_tag_style {
    _id: string
    name: string
    color: string
    background: string
    column: string
    _seq: number
  }

  interface ITag_style {
    _id: string
    name: string
    color: string
    style: string
  }
}

export interface IRecommendContentItem {
  _id: string
  community_id: string
  title: string
  brief: string
  images: void /* undefined */[]
  images_info: void /* undefined */[]
  videos: void /* undefined */[]
  user: {
    _id: string
    name: string
    icon: string
    icon_border_url: string
    count: {
      fans: number
    }
    badge?: {
      icon: string
    }
    auth?: {
      icon: string
    }
  }
  time: {
    create: number
    update: number
    edit: number
    comment: number
    audit: number
  }
  count: {
    vote: number
    comment: number
  }
  bbs: {
    _id: string
    name: string
    type: string
    _seq: number
    game: {
      _id: string
      name: string
      icon: string
      brief: string
      icon_subscript: string
      ori_icon: string
      _seq: number
    }
    icon: string
  }
}
