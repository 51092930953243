import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=26cb9e6a&scoped=true"
import script from "./Detail.vue?vue&type=script&lang=ts"
export * from "./Detail.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Detail.vue?vue&type=style&index=0&id=26cb9e6a&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "26cb9e6a",
  "0a58310a"
  
)

export default component.exports