import { render, staticRenderFns } from "./Comments.vue?vue&type=template&id=31e42a70&scoped=true"
import script from "./Comments.vue?vue&type=script&lang=ts"
export * from "./Comments.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Comments.vue?vue&type=style&index=0&id=31e42a70&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "31e42a70",
  "492ad55b"
  
)

export default component.exports