


























































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import api from "@/api/api"
@Component({
  name: "permissionDialog"
})
export default class permissionDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    game_name: "",
    game_icon: "",
    version: "",
    permissions: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false
}
