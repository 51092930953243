







/**
 * @description APP内打开按钮
 * urlscheme.md https://git.ghzs.com/halo/web/web-page/-/blob/master/app-android-js/urlscheme.md
 */
import { Component, Vue } from "vue-property-decorator"
import downloadDialog from "@/components/downloadDialog"
import { inGhzs, inPlugin } from "@/utils/base"
import wxTipDialog from "@/components/wxTipDialog"

import { appBoundle } from "@/utils/base"
import { openAppLog } from "@/utils/log"
const { openApp } = appBoundle()

@Component({
  name: "OpenAppButton",
  components: {
    // WxOpenApp: () => import("@/components/wxOpenApp/wxOpenApp.vue")
  }
})
export default class OpenAppButton extends Vue {
  showWxOpenApp = false

  isInGhzs = inGhzs()

  isInPlugin = inPlugin()

  get showBtn() {
    return !(this.isInGhzs || this.isInPlugin)
  }

  handleClick() {
    openAppLog()
    if (window.GhzsUa.UA.Wechat || window.GhzsUa.UA.QQ) {
      wxTipDialog(
        {
          rmAfterDestroy: false,
          presetData: {}
        },
        () => {}
      )
    } else {
      openApp(window._launchAppUrl, function() {
        downloadDialog(
          {
            rmAfterDestroy: false,
            presetData: {}
          },
          () => {}
        )
      })
    }
  }

  mounted() {
    // if (window.GhzsUa?.UA.Wechat) {
    //   this.showWxOpenApp = true
    // }
  }
}
