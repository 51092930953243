import { apiBaseUrlV5_5 } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"
import { IListParams } from "../api"
import { getUserToken, inPlugin, checkPluginLogin } from "@/utils/base"

export default function(service: AxiosInstance) {
  return {
    question: {
      // 问答帖详情
      detail(question_id: string): Promise<AxiosResponse<IQues>> {
        return service.get(`/bbses/questions/${question_id}?view=detail`)
      },
      // 问答帖的评论列表
      comments(
        question_id: string,
        params: IListParams
      ): Promise<AxiosResponse<IQuesCommentItem[]>> {
        let headers = {}
        if (inPlugin() && checkPluginLogin()) {
          headers = {
            token: getUserToken()
          }
        }
        return service.get(
          `/bbses/questions/${question_id}/comments?view=bbs_web`,
          {
            params,
            headers
          }
        )
      },
      // 问答帖的评论回复
      commentsReplys(
        question_id: string | number,
        comment_id: string | number
      ): Promise<AxiosResponse<IQuesReplyItem[]>> {
        return service.get(
          `/bbses/questions/${question_id}/comments/${comment_id}/replies`
        )
      }
    }
  }
}

interface IQues {
  _id: string
  title: string
  content: string
  images: void /* undefined */[]
  images_info: void /* undefined */[]
  videos: void /* undefined */[]
  user: IUser
  time: ITime
  type: string
  status: string
  count: ICount_1
  bbs: IBb
}

interface IBb {
  _id: string
  name: string
  type: string
  _seq: number
  game: IGame
}

interface IGame {
  _id: string
  icon: string
  brief: string
  icon_subscript: string
  ori_icon: string
  _seq: number
}

interface ICount_1 {
  answer: number
  reply: number
}

interface ITime {
  create: number
  update: number
  edit: number
  audit: number
}

interface IUser {
  _id: string
  name: string
  icon: string
  icon_border_url: string
  count: ICount
}

interface ICount {
  fans: number
}

export interface IQuesCommentItem {
  _id: string
  content: string
  priority: number
  user: {
    _id: string
    name: string
    icon: string
    count: {
      fans: number
    }
    auth?: boolean
  }
  attached: {
    _id: string
    user: {
      _id: string
      name: string
      icon: string
    }
    content: string
  }[]
  time: number
  vote: number
  reply: number
  type: string
  accept: boolean
  choiceness: boolean
  is_top?: boolean
  images?: string[]
  me?: {
    is_question_commented: boolean
    is_question_comment_voted: boolean
    is_question_own: boolean
  }
}

interface IQuesReplyItem {
  _id: string
  community_id: string
  content: string
  priority: number
  user: {
    _id: string
    name: string
    icon: string
    count: {
      fans: number
    }
  }

  parent_user: {
    _id: string
    name: string
    comment: string
    active: boolean
    comment_id: string
    icon: string
    badge: {
      name: string
      icon: string
      actions: {
        text: string
      }[]
    }
    count: {
      fans: number
    }
  }
  vote: number
  time: number
  me: {
    is_answer_own: boolean
  }
}
