import api from "@/api/api"

export const gameList = {
  namespaced: true,
  state: () => ({
    list: null,
    recommendList: null,
    indexGameList: null,
    videoId: null,
    videoOffsetTop: null,
    hotSearchList: null,
    gameSearchList: null,
    gameAbstract: null,
    gameDetail: null,
    gameRating: null,
    gameComments: null,
    gameCommentDetail: null,
    gameCommentReplies: null,
    filterTags: null,
    gameEvents: null,
    tags: null,
    tagName: ""
  }),
  getters: {
    list: state => state.list,
    filterTags: state => state.filterTags,
    tags: state => state.tags,
    tagName: state => state.tagName
  },
  mutations: {
    SET_LIST: (state, { list }) => {
      state.list = list
    },
    SET_RECOMMEND_LIST: (state, { recommendList }) => {
      state.recommendList = recommendList
    },
    SET_INDEXGAME_LIST: (state, { indexGameList }) => {
      state.indexGameList = indexGameList
    },
    SET_VIDEO_ID: (state, { videoId, videoOffsetTop }) => {
      state.videoId = videoId
      state.videoOffsetTop = videoOffsetTop
    },
    SET_HOT_SEARCH_LIST: (state, { hotSearchList }) => {
      state.hotSearchList = hotSearchList
    },
    SET_GAME_SEARCH_LIST: (state, { gameSearchList }) => {
      state.gameSearchList = gameSearchList
    },
    SET_GAME_ABSTRACT: (state, { gameAbstract }) => {
      state.gameAbstract = gameAbstract
    },
    SET_GAME_DETAIL: (state, { gameDetail }) => {
      state.gameDetail = gameDetail
    },
    SET_GAME_RATING: (state, { gameRating }) => {
      state.gameRating = gameRating
    },
    SET_GAME_COMMENTS: (state, { gameComments }) => {
      state.gameComments = gameComments
    },
    SET_GAME_COMMENT_DETAIL: (state, { gameCommentDetail }) => {
      state.gameCommentDetail = gameCommentDetail
    },
    SET_GAME_COMMENT_REPLIES: (state, { gameCommentReplies }) => {
      state.gameCommentReplies = gameCommentReplies
    },
    SET_FILTER_TAGS: (state, { filterTags }) => {
      state.filterTags = filterTags
    },
    SET_GAME_EVENTS: (state, { gameEvents }) => {
      state.gameEvents = gameEvents
    },
    SET_TAGS: (state, { data }) => {
      state.tags = data
    },
    SET_TAG_NAME: (state, { name }) => {
      state.tagName = name
    }
  },
  actions: {
    FETCH_LIST: ({ commit }, { page = 1, random }) => {
      return api.games
        .homeContentList({ page, page_size: 15, view: "web", random })
        .then(res => commit("SET_LIST", { list: res.data }))
    },
    FETCH_RECOMMEND_LIST: ({ commit }, {}) => {
      return api.games
        .recommendList({ page: 1, page_size: 10, view: "web" })
        .then(res => commit("SET_RECOMMEND_LIST", { recommendList: res.data }))
    },
    FETCH_INDEXGAME_LIST: ({ commit }, {}) => {
      return api.games
        .indexGameList({ page: 1, page_size: 100, view: "web" })
        .then(res => commit("SET_INDEXGAME_LIST", { indexGameList: res.data }))
    },
    FETCH_HOT_SEARCH_LIST: ({ commit }, {}) => {
      return api.games
        .hotSearches({ view: "web" })
        .then(res => commit("SET_HOT_SEARCH_LIST", { hotSearchList: res.data }))
    },
    FETCH_GAME_SEARCH_LIST: ({ commit }, { keyword }) => {
      return api.games
        .gameSearch({ page: 1, page_size: 100, view: "web", keyword })
        .then(res =>
          commit("SET_GAME_SEARCH_LIST", { gameSearchList: res.data })
        )
    },
    FETCH_GAME_ABSTRACT: ({ commit }, { game_id }) => {
      return api.games
        .detail(game_id)
        .then(res => commit("SET_GAME_ABSTRACT", { gameAbstract: res.data }))
    },
    FETCH_GAME_DETAIL: ({ commit }, { game_id }) => {
      return api.games
        .detail(game_id, "web_detail")
        .then(res => commit("SET_GAME_DETAIL", { gameDetail: res.data }))
    },
    FETCH_GAME_RATING: ({ commit }, { game_id }) => {
      return api.games
        .gameRating(game_id)
        .then(res =>
          commit("SET_GAME_RATING", { gameRating: res.data.new_star })
        )
    },
    FETCH_GAME_COMMENTS: ({ commit }, { game_id, params }) => {
      return api.games
        .gameComments(game_id, params)
        .then(res => commit("SET_GAME_COMMENTS", { gameComments: res.data }))
    },
    FETCH_GAME_COMMENT_DETAIL: ({ commit }, { game_id, comment_id }) => {
      return api.games
        .gameCommentDetail(game_id, comment_id)
        .then(res =>
          commit("SET_GAME_COMMENT_DETAIL", { gameCommentDetail: res.data })
        )
    },
    FETCH_GAME_COMMENT_REPLIES: (
      { commit },
      { game_id, comment_id, params }
    ) => {
      return api.games
        .gameCommentReplies(game_id, comment_id, params)
        .then(res =>
          commit("SET_GAME_COMMENT_REPLIES", { gameCommentReplies: res.data })
        )
    },
    FETCH_FILTER_TAGS: ({ commit }) => {
      return api.games
        .filterTags()
        .then(res => commit("SET_FILTER_TAGS", { filterTags: res.data }))
    },
    FETCH_GAME_EVENTS: ({ commit }, { game_id }) => {
      return api.games
        .gameEvents(game_id)
        .then(res => commit("SET_GAME_EVENTS", { gameEvents: res.data }))
    },
    FETCH_TAGS: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const filterTagsResp = await api.games.filterTags()
          const tagsResp = await api.tag.tags(params)

          commit("SET_TAGS", {
            data: tagsResp.data.map(v => {
              if (v.new_tag_style) {
                v.new_tag_style = v.new_tag_style.filter(
                  tag => !filterTagsResp.data.includes(tag.name)
                )
              }
              return v
            })
          })

          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
