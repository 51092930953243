





























































































































































/**
 * @description 搜索结果卡片
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import { TimestampFormat } from "@/utils/base"

@Component({
  name: "ResultCardItem",
  filters: {
    format: (value: number) => TimestampFormat(value)
  },
  components: {
    HaloImg: () => import("@/components/image/image.vue")
  }
})
export default class ResultCardItem extends Vue {
  @Prop({ type: String, default: "" }) username: string // 用户名称
  @Prop({ type: String, default: "" }) avatar: string // 用户头像
  @Prop({ type: String, default: "" }) auth: string // 个人认证
  @Prop({ type: String, default: "" }) title: string // 标题
  @Prop({ type: String, default: "" }) desc: string // 描述
  @Prop({ type: Number, default: null }) time: number // 时间
  @Prop({ type: Number, default: 0 }) vote: number // 点赞量
  @Prop({ type: Number, default: 0 }) comment_count: number // 时间
  @Prop({ type: Array, default: () => [] }) images!: any // 图片
  @Prop({ type: Array, default: () => [] }) images_info!: any // 图片尺寸信息
  @Prop({ type: Array, default: () => [] }) videos!: any // 视频尺寸
  @Prop({ type: String, default: "" }) badge!: string // 用户徽章
  @Prop({ type: String, default: "" }) type: string // 类型

  get isQues() {
    return this.type === "bbs_question" || this.type === "question"
  }

  isLongImg: boolean = false // 是否是长图

  get _images() {
    const vLen = this.videos.length
    const end = 3 - vLen < 0 ? 0 : 3 - vLen
    return this.images.slice(0, end)
  }

  // 图片适配
  getSuitImgSize(index: number, type: string) {
    let w = 32.53
    let h = 30
    let width
    let height

    if (type === "videos") {
      width = this.videos[index].width
      height = this.videos[index].height
    } else if (this.images_info[index]) {
      width = this.images_info[index].width
      height = this.images_info[index].height
    }

    if (this.images.length + this.videos.length === 1) {
      const scale = height / width
      if (scale > 18 / 9) {
        // 比例18:9
        w = 60
        h = 80
        this.isLongImg = true
      } else if (scale <= 2 / 3) {
        // 比例3:2
        w = 60
        h = 40
      } else if (scale > 2 / 3 && scale <= 18 / 9) {
        // 比例3:2 ～ 18:9
        w = 60
        h = 60
      }
    }
    return {
      width: `${w}vw`,
      height: `${h}vw`
    }
  }
}
