const TARGET_NODE = process.env.VUE_ENV === "server"

function getTdk(vm) {
  const { tdk } = vm.$options
  if (tdk) {
    return tdk.call(vm)
  }
}

function updateTdk(vm, vdom) {
  const tdk = getTdk(vm)
  vdom.title = (tdk && tdk.title) || "光环助手"
  vdom.description = (tdk && tdk.description) || ""
  vdom.keywords = (tdk && tdk.keywords) || ""
  vdom.icon =
    (tdk && tdk.icon) || "https://static-web.ghzs.com/official-site/logo.png"
}

function serverUpdateTdk() {
  // updateTdk(this, this.$ssrContext)
  const tdk = getTdk(this)
  this.$ssrContext.title = (tdk && tdk.title) || "光环助手"
  this.$ssrContext.description = (tdk && tdk.description) || ""
  this.$ssrContext.keywords = (tdk && tdk.keywords) || ""
  this.$ssrContext.icon =
    (tdk && tdk.icon) || "https://static-web.ghzs.com/official-site/logo.png"
}

function clientUpdateTdk() {
  // updateTdk(this, document)
  const tdk = getTdk(this)
  document.title = (tdk && tdk.title) || "光环助手"

  const $desc = document.querySelector<any>("meta[name='description']")
  if ($desc) {
    $desc.content = tdk.description
  }
  const $keyword = document.querySelector<any>("meta[name='keywords']")
  if ($keyword) {
    $keyword.content = tdk.keywords
  }
  const $icon = document.querySelector<any>("meta[property='og:image']")
  if ($icon) {
    $icon.content =
      (tdk && tdk.icon) || "https://static-web.ghzs.com/official-site/logo.png"
  }
}

export default TARGET_NODE ? serverUpdateTdk : clientUpdateTdk

type replaceKey =
  | "{标签名称}"
  | "{游戏名称}"
  | "{游戏简介}"
  | "{用户ID}"
  | "{评论内容}"
  | "{文章标题}"
  | "{文章简介}"
  | "{文章内容}"
  | "{论坛名称}"
  | "{文章正文内容}"
  | "{下载按钮文案}"
  | "{专题名称}"
  | "{关键词}"
  | "{问题标题}"
  | "{问题描述}"
interface IReplaceItem {
  replaceKey: replaceKey
  replaceVal: string
}

export const formatTDK = (
  { title, description, keywords },
  replaces: IReplaceItem[]
) => {
  function _replace(str) {
    let _str = str
    replaces.forEach(item => {
      _str = _str.replace(new RegExp(item.replaceKey, "g"), item.replaceVal)
    })
    return _str
  }
  return {
    title: _replace(title),
    description: _replace(description),
    keywords: _replace(keywords)
  }
}
