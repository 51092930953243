import Vue from "vue"
import dialog from "./downloadDialog.vue"

const DialogConstructor = Vue.extend(dialog)

let instance: any

interface IOption {
  rmAfterDestroy?: boolean
  presetData?: any
}

const defaultConifg = {
  rmAfterDestroy: true,
  presetData: {
    title: ""
  }
}

const removableDialog = (
  options: IOption,
  onCloseFn?: Function | undefined,
  onOpenFn?: Function | undefined
) => {
  const mergeOptions = Object.assign({}, defaultConifg, options)

  const { rmAfterDestroy, presetData } = mergeOptions

  if (!instance) {
    instance = new DialogConstructor({
      mounted() {
        onOpenFn && onOpenFn()
      },
      watch: {
        dialogVisible: {
          handler(change) {
            this.dialogVisible = change

            if (rmAfterDestroy) {
              if (change === false) {
                this.$nextTick(() => {
                  instance.isRender = false
                  this.$nextTick(() => {
                    instance.$destroy()
                    instance = null
                  })
                })
              }
            }

            if (change === false && this.isSubmit) {
              onCloseFn && onCloseFn({})
            }
          }
        }
      }
    })

    instance.$mount()

    document.body.appendChild(instance.$el)

    if (!instance.dialogVisible) {
      Vue.nextTick(() => {
        Vue.set(instance, "dialogDataPresets", presetData)
        instance.dialogVisible = true
      })
    }
  } else if (instance && !instance.dialogVisible) {
    Vue.nextTick(() => {
      Vue.set(instance, "dialogDataPresets", presetData)
      instance.dialogVisible = true
    })
  }

  return instance
}

export default removableDialog
