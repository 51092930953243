import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=5892c64f&scoped=true"
import script from "./Result.vue?vue&type=script&lang=ts"
export * from "./Result.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Result.vue?vue&type=style&index=0&id=5892c64f&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5892c64f",
  "5ad12730"
  
)

export default component.exports