











import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "Home"
})
export default class Home extends Vue {
  titleIcon: string = require("@/assets/images/文字logo.png")
  searchIcon: string = require("@/assets/images/icon_search.png")

  handleSearch() {
    if (this.$route.name === "toolkit") {
      this.$router.push("/toolkit/search")
      return
    }
    this.$router.push("/search")
  }

  handleLogo() {
    this.$router.push("/")
  }
}
