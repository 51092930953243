












































import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "OfficialList",
  components: {}
})
export default class OfficialList extends Vue {
  openIcon: string = require("@/assets/images/icon-展开内容.png")
  closeIcon: string = require("@/assets/images/icon-收起内容.png")

  isShowAll = false

  get officialList() {
    return this.$root.$tstore.modules.bbs.getters.officialBbsList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.officialList) {
      this.fetchData()
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.bbs.actions.FETCH_OFFICIAL_BBS()
  }

  // handleSelect(id: number) {
  //   this.$router.push({ path: `/bbs/${id}` })
  // }

  handleShowHide() {
    this.isShowAll = !this.isShowAll
  }
}
