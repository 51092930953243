






















import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "Des"
})
export default class Des extends Vue {
  $refs: { content: HTMLElement }

  @Prop({ type: String, default: "" })
  des: string

  isFold = false
  get getContent() {
    const maxLen = Math.floor(this.$refs.content?.offsetWidth / 12) * 2 - 6
    return this.isFold ? this.des.slice(0, maxLen) + "..." : this.des
  }

  @Watch("des", { immediate: true })
  onDes(des) {
    if (des) {
      this.$nextTick(() => {
        if (this.$refs.content?.offsetHeight > 18 * 2) {
          this.isFold = true
        }
      })
    }
  }
}
