















import { IArticle } from "@/api/modules/basic"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { DateFotmat } from "@/utils/base"

@Component({
  name: "GameItem",
  components: {
    Item: () => import("../Item/Item.vue")
  }
})
export default class GameItem extends Vue {
  DateFotmat = DateFotmat

  get list() {
    return this.$root.$tstore.modules.basic.getters.juheArticleList
  }

  createLink(item: IArticle) {
    if (item.link.type === "community_article") {
      return "/bbs/thread-" + item._seq
    }
    if (item.link.type === "article") {
      return "/article/" + item._seq
    }
    return "/"
  }
}
