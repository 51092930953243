






import { IToolkitList } from "@/api/modules/toolkit"
import { TLocalStorageTypes } from "@/config"
import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "Toolkit",
  components: {
    Card: () => import("./components/card/card.vue"),
    Footer: () => import("@/components/footer/footer.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Toolkit extends Vue {
  get originList() {
    return this.$root.$tstore.modules.toolkit.getters.list
  }

  list = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.originList) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  fetchData() {
    return Promise.all([
      this.$root.$tstore.modules.toolkit.actions.FETCH_LIST(),
      this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
        type: "manual",
        _id_or_name: encodeURI("工具箱集合页")
      })
    ])
      .then(() => {
        this.formatData()
        this.setTDKCache()
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        updateTdk.call(this)
      })
  }

  formatData() {
    this.list = JSON.parse(JSON.stringify(this.originList))

    if (
      !this.$isServer &&
      localStorage.getItem(TLocalStorageTypes.CLICKEDTOOLS)
    ) {
      const data: IToolkitList[] = JSON.parse(
        localStorage.getItem(TLocalStorageTypes.CLICKEDTOOLS)
      )
      this.list = [
        {
          _id: "none",
          name: "最近",
          total: data.length,
          data: data.reverse()
        }
      ].concat(this.list)
    }
  }

  /** tdk */
  mounted() {
    this.checkTDK()
  }
  setTDKCache() {
    const {
      title,
      keywords,
      description
    } = this.$root.$tstore.modules.tdk.getters
    this.$root.$tstore.modules.tdk.mutations.SET_CACHE({
      path: this.$route.fullPath,
      tdk: JSON.stringify({ title, keywords, description })
    })
  }
  checkTDK() {
    if (this.$root.$tstore.modules.tdk.getters.caches[this.$route.fullPath]) {
      const tdk = JSON.parse(
        this.$root.$tstore.modules.tdk.getters.caches[this.$route.fullPath]
      )
      this.$root.$tstore.modules.tdk.mutations.SET_TDK(tdk)
      updateTdk.call(this)
    }
  }
}
