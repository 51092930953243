















































import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "SearchTip"
})
export default class SearchTip extends Vue {
  historyList = []
  get hotSearchList() {
    return this.$store.state.gameList.hotSearchList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (localStorage.getItem("HISTORY_LIST")) {
      this.historyList = JSON.parse(localStorage.getItem("HISTORY_LIST"))
    }
    if (!this.hotSearchList) {
      this.fetchData()
    }
  }

  fetchData() {
    return this.$store
      .dispatch("gameList/FETCH_HOT_SEARCH_LIST", {})
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
  }

  handleClear() {
    this.historyList = []
    localStorage.removeItem("HISTORY_LIST")
  }

  handleSearch(keyword) {
    this.$router.push(`/search/${keyword}`)
  }
}
