












































import { Component, Vue, Watch } from "vue-property-decorator"
import api, { MBbsContent } from "@/api/api"
@Component({
  name: "JinghuaList",
  beforeRouteLeave(to, from, next) {
    this.$root.$tstore.modules.content.mutations.RESET_JINGHUA()
    next()
  },
  components: {
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    Img: () => import("@/components/image/image.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    VideoCard: () => import("./VideoCard.vue")
  }
})
export default class Content extends Vue {
  get jinghua() {
    return this.$root.$tstore.modules.content.getters.jinghua
  }

  loading = false

  nextList = []

  beforeMount() {
    if (this.jinghua.data.length === 0) {
      this.getJinghuaList()
    }
  }

  /** 客户端请求数据 */
  async getJinghuaList() {
    this.loading = true
    const res = await api.content.jinghuaList(this.$route.params.bbs_id, {
      page: this.jinghua.page,
      page_size: this.jinghua.page_size
    })

    if (res.data.length > 0) {
      this.$root.$tstore.modules.content.mutations.SET_JINGHUA({
        data: this.jinghua.data.concat(res.data),
        page: this.jinghua.page + 1
      })
      this.prefetchNextPage()
    } else {
      this.$root.$tstore.modules.content.mutations.SET_JINGHUA({
        finished: true
      })
    }
    this.loading = false
  }

  handleLoadMore() {
    this.$root.$tstore.modules.content.mutations.SET_JINGHUA({
      data: this.jinghua.data.concat(this.nextList),
      page: this.jinghua.page + 1
    })
    this.prefetchNextPage()
  }

  async prefetchNextPage() {
    const nextRes = await api.content.jinghuaList(this.$route.params.bbs_id, {
      page: this.jinghua.page,
      page_size: this.jinghua.page_size
    })
    if (nextRes.data.length === 0) {
      this.$root.$tstore.modules.content.mutations.SET_JINGHUA({
        finished: true
      })
      this.nextList = []
    } else {
      this.nextList = nextRes.data
    }
  }

  createUrl(item: MBbsContent.IContent) {
    return item.type === "bbs_article"
      ? `/bbs/thread-${item._seq || item._id}`
      : `/bbs/video-${item._seq || item._id}`
  }
}
