






import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  name: "LoadMoreBtn"
})
export default class LoadMoreBtn extends Vue {
  handleClick() {
    this.$emit("handleClick")
  }
}
