import { render, staticRenderFns } from "./TopVideo.vue?vue&type=template&id=34710580&scoped=true"
import script from "./TopVideo.vue?vue&type=script&lang=ts"
export * from "./TopVideo.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./TopVideo.vue?vue&type=style&index=0&id=34710580&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "34710580",
  "09110d9b"
  
)

export default component.exports