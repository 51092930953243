










































import api from "@/api/api"
import { IRankingDataItem } from "@/store/modules/ranking"
import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
@Component({
  name: "Ranking",
  beforeRouteLeave(to, from, next) {
    this.saveScollTop()
    next()
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.$nextTick(() => {
        if (vm.scroll) {
          vm.$parent.$refs.homeMain.scrollTop = vm.scroll
        }
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (this.list) {
      const idx =
        this.list.map(v => v.path).indexOf(to.params.path) > -1
          ? this.list.map(v => v.path).indexOf(to.params.path)
          : 0
      this.selectedColumnId = this.list[idx].column_id
      this.onColChange(this.selectedColumnId)

      this.$root.$tstore.modules.tdk.mutations.SET_TDK(
        this.tdkMap[to.params.path]
      )
      updateTdk.call(this)
    }

    next()
  },
  components: {
    GameItem: () => import("./components/GameItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Ranking extends Vue {
  scroll = 0
  saveScollTop() {
    if (!this.$isServer && this.$parent && this.$parent.$refs.homeMain) {
      this.scroll = (this.$parent.$refs.homeMain as Element).scrollTop || 0
    }
  }

  loading = false
  get list() {
    return this.$root.$tstore.modules.ranking.getters.list
  }

  get selectedColumn() {
    return this.$root.$tstore.modules.ranking.getters.selectedColumn
  }

  selectedColumnId = ""
  onColChange(column_id) {
    this.$root.$tstore.modules.ranking.mutations.SET_SELECTED_COLUMN_ID({
      column_id
    })
    const idx = this.list.map(v => v.column_id).indexOf(this.selectedColumnId)
    if (this.list[idx].page === 1) {
      this.getColumnGames(idx)
    }
  }

  // ssr hook
  serverPrefetch() {
    return Promise.all([
      this.$root.$tstore.modules.ranking.actions.FETCH_LIST({
        path: this.$route.params.path
      })
      // this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
      //   type: "manual",
      //   _id_or_name: encodeURI("排行榜页")
      // })
    ]).finally(() => {
      this.$root.$tstore.modules.tdk.mutations.SET_TDK(
        this.tdkMap[this.$route.params.path]
      )
      updateTdk.call(this)
    })
  }

  async beforeMount() {
    if (!this.list) {
      await this.$root.$tstore.modules.ranking.actions.FETCH_LIST({
        path: this.$route.params.path
      })
    }
    const idx =
      this.list.map(v => v.path).indexOf(this.$route.params.path) > -1
        ? this.list.map(v => v.path).indexOf(this.$route.params.path)
        : 0
    this.selectedColumnId = this.list[idx].column_id

    // await this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
    //   type: "manual",
    //   _id_or_name: encodeURI("排行榜页")
    // })
    this.$root.$tstore.modules.tdk.mutations.SET_TDK(
      this.tdkMap[this.$route.params.path]
    )
    updateTdk.call(this)
  }

  async getColumnGames(idx: number) {
    const list: IRankingDataItem[] = JSON.parse(JSON.stringify(this.list))

    if (list[idx].page === 1 || list[idx].total > list[idx].data.length) {
      this.loading = true
      const { column_id, page, page_size } = list[idx]
      const res = await api.column.columnGames(column_id, { page, page_size })

      list[idx].data = list[idx].data.concat(res.data)
      list[idx].page++
      list[idx].total = parseInt(res.headers.total)
      if (list[idx].data.length >= list[idx].total) {
        list[idx].finished = true
      }
      this.$root.$tstore.modules.ranking.mutations.SET_LIST({ list })
      this.loading = false
    }
  }

  handleLoadMore() {
    const idx = this.list.map(v => v.column_id).indexOf(this.selectedColumnId)
    this.getColumnGames(idx)
  }

  tdkMap = {
    hot: {
      title: "手游排行榜_热门手游排行榜_热门榜-光环助手网页版",
      keywords:
        "光环助手网页版，手机游戏排行榜，手游热门排行榜，手游热门排名，热门的手机游戏",
      description:
        "光环助手热门榜为您提供当下热门的手机游戏排行榜，想知道热门的手机游戏是什么，了解更多的新品手游以及高人气手游，快速掌握热门的高质量手游排名，就上光环助手网页版。"
    },
    "stand-alone": {
      title: "手游排行榜_单机手游排行榜_单机榜-光环助手网页版",
      keywords:
        "光环助手网页版，手机游戏排行榜，手游热门排行榜，手游热门排名，热门的手机游戏",
      description:
        "光环助手单机榜为您提供当下热门的单机游戏排行榜，想知道热门的单机游戏是什么，了解更多的新品手游以及高人气手游，快速掌握热门的高质量手游排名，就上光环助手网页版。"
    },
    new: {
      title: "手游排行榜_新品手游排行榜_新品榜-光环助手网页版",
      keywords:
        "光环助手网页版，手机游戏排行榜，新品手游排行榜，新品手游排名，新发行的手游排行",
      description:
        "光环助手新品榜为您提供当下新发行的手机游戏排行榜，想知道新发行的手机游戏是什么，了解更多的新品手游以及高人气手游，快速掌握热门的高质量手游排名，就上光环助手网页版。"
    },
    simulator: {
      title: "手游排行榜_手机模拟器游戏排行榜_模拟器-光环助手网页版",
      keywords:
        "光环助手网页版，手机游戏排行榜，模拟器手游排行榜，模拟器手游排名，手机模拟器游戏",
      description:
        "光环助手模拟器榜为您提供当下热门的手机模拟器游戏排行榜，想知道热门的手机模拟器游戏是什么，了解更多的新品手游以及高人气手游，快速掌握高质量模拟器手游排名，就上光环助手网页版。"
    },
    expect: {
      title: "手游排行榜_手机游戏预约排行榜_预约榜-光环助手网页版",
      keywords:
        "光环助手网页版，手机游戏排行榜，手游预约排行榜，手游预约排名，手机游戏预约",
      description:
        "光环助手预约榜为您提供即将推出的高人气手机游戏预约排行榜，想知道预约人气的手机游戏是什么，了解更多的新品手游以及高人气手游，快速掌握高人气的手机游戏预约排名，就上光环助手网页版"
    }
  }
}
