



























































































































































import { Component, Vue } from "vue-property-decorator"
import shareDialog from "@/components/shareDialog"
import updateTdk, { formatTDK } from "@/utils/tdk"
import {
  inGhzs,
  timeago,
  urlScheme,
  inPlugin,
  getUserToken,
  checkPluginLogin
} from "@/utils/base"
import { useContentApi } from "@/api/useContentApi"
import Axios from "axios"

@Component({
  name: "CommunityArticle",
  components: {
    Content: () => import("./components/content.vue"),
    Comment: () => import("./components/comment.vue"),
    FloatDownload: () => import("@/components/floatDownload/floatDownload.vue"),
    CommentInput: () => import("@/components/CommentInput"),
    OpenAppButton: () => import("@/components/openAppButton/openAppButton.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class CommunityArticle extends Vue {
  isInGhzs = inGhzs()
  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()

  timeago = timeago
  get detail() {
    return this.$root.$tstore.modules.communityArticle.getters.detail
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  async beforeMount() {
    if (!this.detail) {
      await this.fetchData()
    }

    if (!this.$isServer) {
      window._launchAppUrl = urlScheme.communityArticle(
        this.detail._id,
        this.detail.community_id
      )
    }
  }
  mounted() {
    if (this.isInPlugin && this.isPluginLogin && getUserToken()) {
      this.getStatus()
    }
  }

  destroyed() {
    this.$root.$tstore.modules.communityArticle.mutations.SET_DETAIL({
      detail: null
    })
  }

  fetchData() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.communityArticle.actions.FETCH_DETAIL({
          bbs_id: this.$route.params.bbs_id,
          article_id: this.$route.params.article_id
        })

        await this.$store.dispatch("tdk/FETCH_SUB_TDK", {
          type: "bbs",
          _id_or_name: this.$route.params.bbs_id,
          sub_type: "article"
        })

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.detail.community?.name || ""
            },
            {
              replaceKey: "{文章标题}",
              replaceVal: this.detail.title
            },
            {
              replaceKey: "{文章正文内容}",
              replaceVal: this.detail.content
                .replace(/<\/?.+?\/?>/g, "")
                .replace(/\n/g, "")
                .replace(/&nbsp/g, "")
                .substr(0, 50)
            },
            {
              replaceKey: "{文章内容}",
              replaceVal: this.detail.content
                .replace(/<\/?.+?\/?>/g, "")
                .replace(/\n/g, "")
                .replace(/&nbsp/g, "")
                .substr(0, 50)
            },
            {
              replaceKey: "{论坛名称}",
              replaceVal: this.detail.community?.name || ""
            }
          ])
        )
        updateTdk.call(this)

        resolve("")
      } catch (error) {
        reject(error)
        if (!this.$isServer) {
          this.$router.push("/404")
        }
      }
    })
  }

  async getStatus() {
    const api = useContentApi(getUserToken())
    const res = await api.communityArticleDetail(this.$route.params.article_id)
    this.isVote = res.data?.me?.is_vote || false
    this.isStar = res.data?.me?.is_favorite || false
  }

  /** 顶部 */
  showTitle = false
  onScroll({ scrollTop, isFixed }) {
    this.showTitle = scrollTop > 30
  }

  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  handleDownload() {
    if (this.isInPlugin) {
      return
    }
    this.$downloadDialog()
  }

  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }

  showFloatDownload = !(this.isInGhzs || this.isInPlugin)
  // 关闭下载提示条
  handleCloseFloatDownLoad() {
    this.showFloatDownload = false
  }

  isVote = false
  async onVote(change) {
    console.log("onVote")
    const api = useContentApi(getUserToken())
    if (change) {
      try {
        await api.vote.communityArticle(this.detail._id)
        this.isVote = change
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await api.unvote.communityArticle(this.detail._id)
        this.isVote = change
      } catch (error) {
        console.error(error)
      }
    }
  }

  isStar = false
  async onStar(change) {
    console.log("onStar", change)
    const api = useContentApi(getUserToken())
    if (change) {
      try {
        await api.favorites.communityArticle(this.detail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await api.cancel_favorites.communityArticle(this.detail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    }
  }

  commentKey = 0
  async onComment(content) {
    const api = useContentApi(getUserToken())
    try {
      await api.comment.communityArticle(this.detail._id, { content })
      this.commentKey++
    } catch (error) {
      console.error(error)
    }
  }
}
