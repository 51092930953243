






















import { Component, Vue, Prop } from "vue-property-decorator"
import { timeago } from "@/utils/base"
import { MBasic } from "@/api/modules/basic"

@Component({
  name: "ChoiceItem",
  components: {}
})
export default class ChoiceItem extends Vue {
  @Prop({ type: Object, default: () => {} }) data: MBasic.ISeoChoiceItem

  timeago = timeago
}
