

























import { timeago, urlScheme } from "@/utils/base"
import { Component, Vue, Prop, Watch, Inject } from "vue-property-decorator"

interface IComment {
  _id: string
  content: string
  order: number
  vote: number
  active: boolean
  user: User
  star: number
  version: Version
  device: string
  time: number
  reply: number
  timeago?: string
}
interface Version {
  game: string
}
interface User {
  _id: string
  name: string
  icon: string
}

@Component({
  name: "CommentArea",
  components: {
    CommentCard: () =>
      import(
        "@/views/Game/components/Comments/components/CommentCard/CommentCard.vue"
      )
  }
})
export default class CommentArea extends Vue {
  urlScheme = urlScheme

  @Inject("changeTab")
  changeTab

  @Prop({ type: Array, default: () => [] })
  commentProp: IComment[]

  star = {
    icon: require("@/assets/images/anli_Star_gray.png"),
    activeIcon: require("@/assets/images/anli_Star_blue.png")
  }

  comments = []

  @Watch("commentProp", { immediate: true })
  onData(data: IComment) {
    if (data) {
      this.comments = this.commentProp.map(v => {
        v.timeago = timeago(v.time * 1000)
        return v
      })
    }
  }
}
