





















import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { ImagePreview } from "vant"
@Component({
  name: "Gallery",
  components: {}
})
export default class Gallery extends Vue {
  @Prop({ type: Array, default: () => [] })
  gallery: string[]

  preview(images, idx) {
    ImagePreview({
      images,
      startPosition: idx
    })
  }
}
